import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate, useOutletContext } from "react-router-dom";
import { doc, updateDoc, arrayUnion, arrayRemove, deleteDoc, setDoc, collection, increment, getDocs, where, query, runTransaction } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import './story.css';
import { formatStoryText } from "../../utilities/formatStoryText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faStar, faEye, faHeart, faTrashAlt, faComments } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as faThumbsUpOutline } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';
import { faHeartCircleCheck } from "@fortawesome/free-solid-svg-icons/faHeartCircleCheck";
import Filter from 'bad-words';
import { ReadNext } from "./story components/readNext";
import { StoryShare } from "./story components/storyShare";
import { FlagContent } from "./story components/flagContent";
import { DisplayGenreIcon } from "../../utilities/displayGenreIcon";
import JsonToReact from "../../utilities/jsonToReact";
import { formatDate } from "../../utilities/formatDate";
import { useDispatch } from "react-redux";
import { setActivity, setUser, setStories, setUsers } from "../../app/appSlice";
import { SelectFeature } from "./story components/selectFeature";
import { Pagination } from "@mui/material";
//import { useMediaQuery } from "@mui/material";
//import ConditionalSwipeWrapper from "../../utilities/conditionalSwiper";

export const Story = () => {
    const { storyId, username } = useParams();
    const { stories, users, activity, user } = useOutletContext(); // Get stories array from outlet context
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [story, setStory] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState("");
    const [selectedRating, setSelectedRating] = useState(0);
    const [reply, setReply] = useState("");
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [fontSize, setFontSize] = useState(18); // Initial font size in pixels
    const [visibleReplies, setVisibleReplies] = useState(new Set()); // To track visible replies
    const [visibleComments, setVisibleComments] = useState(new Set());
    const [showBookmarkButton, setShowBookmarkButton] = useState(false);
    const [storyPages, setStoryPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [pagesByParagraph, setPagesByParagraph] = useState([]);
    const firstParagraphRef = useRef(null); // Create a ref for the first paragraph
    //const isMobile = useMediaQuery('(max-width:768px)');



    // Setting story data and user rating
    useEffect(() => {
        if (username && storyId) {
            const storyData = stories.find(story => story.uid === storyId);
            if (storyData) {
                setStory(storyData);

                // Set user rating if it exists
                if (user) {
                    const userRating = storyData.ratings?.find(r => r.user === user.username);
                    if (userRating) {
                        setSelectedRating(userRating.rating || 0);
                    }
                }
            } else {
                setError("Story not found.");
            }
        }
        setIsLoading(false);
    }, [username, storyId, stories, user]);

// Enhanced function to dynamically calculate words per page for mobile, considering line breaks
// function getWordsPerPage(text) {
//     const viewportHeight = window.innerHeight;
//     const fontSize = parseFloat(window.getComputedStyle(document.body).fontSize) || 18;
//     const lineHeight = parseFloat(window.getComputedStyle(document.body).lineHeight) || fontSize * 1.8;
//     const linesPerScreen = Math.floor(viewportHeight / lineHeight);

//     // Count words and paragraphs, giving more weight to paragraphs with more line breaks
//     const paragraphs = text.split("</p>").filter(p => p.trim() !== "");
//     let estimatedLines = 0;

//     paragraphs.forEach(paragraph => {
//         const words = paragraph.trim().split(/\s+/).length;
//         const estimatedLinesForParagraph = Math.ceil(words / 6); // Estimate lines per paragraph

//         // Heavily weight paragraphs that are likely to be short (like dialogue lines)
//         const lineBreaks = (paragraph.match(/<br\s*\/?>/g) || []).length;
//         estimatedLines += estimatedLinesForParagraph + lineBreaks * 0.5; // Adding extra for line breaks
//     });

//     // Calculate words per page based on lines available on screen
//     const linesAvailable = Math.min(linesPerScreen, estimatedLines);
//     return Math.floor(linesAvailable * 6); // 6 words per line as an average
// }

useEffect(() => {
    const paginateStory = (text, wordsPerPage) => {
        const paragraphs = text.split("</p>").filter(p => p.trim() !== "");
        const pages = [];
        let currentPage = [];
        let currentWordCount = 0;
        let paragraphsPerPage = 0;
        let pagesByParagraph = [];

        paragraphs.forEach(paragraph => {
            const wordCount = paragraph.split(" ").length;

            // Add paragraph to the current page if it fits
            if (currentWordCount + wordCount <= wordsPerPage) {
                currentPage.push(paragraph);
                paragraphsPerPage++;
                currentWordCount += wordCount;
            } else {
                // Push the current page to pages and store paragraph count
                if (currentPage.length > 0) {
                    pages.push(currentPage.join("\n"));
                    pagesByParagraph.push(paragraphsPerPage);
                }
                
                // Start new page with the current paragraph
                currentPage = [paragraph];
                currentWordCount = wordCount;
                paragraphsPerPage = 1; 
            }
        });

        // Push last page if there is leftover content
        if (currentPage.length > 0) {
            pages.push(currentPage.join("\n"));
            pagesByParagraph.push(paragraphsPerPage);
        }

        return { pages, pagesByParagraph };
    };

    if (story) {
        //const isMobile = window.innerWidth <= 768; isMobile ? getWordsPerPage(formatStoryText(story.story)) :  
        const wordsPerPage = story.wordCount > 1250 && story.wordCount < 1500 ? story.wordCount : 1250; 
        const { pages, pagesByParagraph } = paginateStory(formatStoryText(story.story), wordsPerPage);
        setStoryPages(pages);
        setPagesByParagraph(pagesByParagraph);
        setCurrentPage(1);
    }

}, [story]);


    // Handle URL hash for scrolling to a specific paragraph
    useEffect(() => {

        const locationHash = window.location.hash;
        // Scroll to #story-page if no hash is present
        if (!locationHash) {
            setTimeout(() => {
                document.getElementById("story-page").scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the element
                }, 200); // Delay to allow page rendering

        } else if (storyPages.length > 0) {
            const paragraphId = locationHash.replace("#para-", ""); // Remove "#para-" to get the number
            const targetParagraphNumber = parseInt(paragraphId, 10);
    
            if (!isNaN(targetParagraphNumber)) {
                // Calculate the page where the target paragraph exists
                let cumulativeParagraphCount = 0;
                let targetPage = 0;
    
                for (let i = 0; i < pagesByParagraph.length; i++) {
                    cumulativeParagraphCount += pagesByParagraph[i];
                    if (targetParagraphNumber <= cumulativeParagraphCount) {
                        targetPage = i + 1; // Pages are 1-based, so add 1
                        break;
                    }
                }
    
                // Only set currentPage if it changes
                if (targetPage > 0 && targetPage !== currentPage) {
                    setCurrentPage(targetPage); // Switch to the page with the target paragraph
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [story?.id]); 
        // eslint-disable-next-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        if (currentPage && window.location.hash) {
            const paragraphId = window.location.hash.replace("#", "");
            const paragraphElement = document.getElementById(paragraphId);

            if (paragraphElement) {
                setTimeout(() => {
                    paragraphElement.scrollIntoView({ behavior: "smooth" });
                }, 200); // Delay for page rendering before scrolling
            }
        }
    }, [currentPage, storyPages]); // This effect still runs when currentPage or storyPages change


    
    // Handle scroll to show or hide the bookmark button based on story position
    useEffect(() => {
        const handleScroll = () => {
            const storyElement = document.getElementById('indyStory'); // ID of the story container
    
            // Check if the element exists before trying to access its properties
            if (storyElement) {
                const storyRect = storyElement.getBoundingClientRect();
    
                // Check if the story element is in view (or close to being in view)
                if (storyRect.top <= window.innerHeight * 0.1) {
                    setShowBookmarkButton(true);
                } else {
                    setShowBookmarkButton(false);
                }
            }
        };
    
        // Listen to scroll events
        window.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    
    
    // Handle page changes (e.g., pagination)
    const handleChangePage = (event, newPage) => {
        navigate(`/${username}/${storyId}`);
        setCurrentPage(newPage);
    
        if (firstParagraphRef.current) {
            // Delay to allow the page content to load or render if necessary
            setTimeout(() => {
                const elementPosition = firstParagraphRef.current.getBoundingClientRect().top + window.scrollY;
                const offset = 100; // Adjust this offset as needed
    
                // Scroll to the element's position with an offset
                window.scrollTo({
                    top: elementPosition - offset,
                    behavior: "smooth"
                });
            }, 200); // Delay for page rendering before scrolling
        }
    };
    

    
    // Updating page views (if the story is loaded)
    useEffect(() => {
        const updatePageViews = async () => {
            try {
                const hasViewed = sessionStorage.getItem(`viewed_${storyId}`);
                if (!hasViewed) {
                    const storyRef = doc(firestore, "stories", storyId);
                    if (user?.username !== story.username || !user) {
                        if (!story.views) {
                            await updateDoc(storyRef, { views: 1 });
                        } else {
                            await updateDoc(storyRef, { views: increment(1) });
                        }
    
                        const updatedStory = { ...story, views: (story.views || 0) + 1 };
                        setStory(updatedStory);
                        const updatedStories = stories.map(s => s.uid === storyId ? updatedStory : s);
                        dispatch(setStories(updatedStories));
                    }
                    sessionStorage.setItem(`viewed_${storyId}`, 'true');
                }
            } catch (err) {
                console.error("Error updating page views:", err);
                setError("Failed to update views");
            }
        };
    
        if (story) {
            updatePageViews();
        }
    }, [story, storyId, user, stories, dispatch, isLoading]);


    const handleBookmark = () => {
        const paragraphs = document.querySelectorAll("p[id]"); // Get all paragraph elements with IDs
        const bookmarkButton = document.querySelector(".fixed-bookmark-button");
        if (paragraphs.length === 0) return;
        const bookmarkButtonRect = bookmarkButton.getBoundingClientRect(); // Get the bookmark button's position
        let closestParagraph = null;
        let closestDistance = Infinity;

        paragraphs.forEach(paragraph => {
            const paragraphRect = paragraph.getBoundingClientRect(); // Get the paragraph's position
            const distance = Math.abs(paragraphRect.top - bookmarkButtonRect.top); // Calculate the distance from the bookmark button
            if (distance < closestDistance) {
                closestDistance = distance;
                closestParagraph = paragraph;
            }
        });

        if (closestParagraph) {
            const paragraphId = closestParagraph.id;
            const bookmarkData = {
                storyId: story.id,
                paragraphId, // Save the paragraph ID
                author: story.user,
                title: story.title,
                timestamp: new Date().toISOString(),
            };
            saveBookmark(bookmarkData); // Save the bookmark
        }
    };

    const saveBookmark = async (bookmarkData) => {
        if (!user) return; // Ensure the user is logged in
        const userRef = doc(firestore, "users", user.username); // Reference to the user's document
        try {
            const existingBookmarks = user.bookmarks || [];
            const existingBookmarkForStory = existingBookmarks.find(bm => bm.storyId === bookmarkData.storyId);

            if (existingBookmarkForStory) {
                await updateDoc(userRef, {
                    bookmarks: arrayRemove(existingBookmarkForStory),
                });
            }

            await updateDoc(userRef, {
                bookmarks: arrayUnion(bookmarkData),
            });

            alert('Bookmark saved');
            const updatedBookmarks = [
                ...existingBookmarks.filter(bm => bm.storyId !== bookmarkData.storyId),
                bookmarkData,
            ];
            const updatedUser = { ...user, bookmarks: updatedBookmarks };
            dispatch(setUser(updatedUser));
        } catch (error) {
            console.error("Error saving bookmark: ", error);
        }
    };


    const addActivity = async (action, rating, id) => {

        if (!user) return;
        if (!rating) { rating = 0 };

        const newDocRef = doc(collection(firestore, `activity`));

        const uid = newDocRef.id;

        const docData = {
            user: user.username,
            username: story.user,
            action: action,
            title: story.title,
            time: new Date().toISOString(),
            uid: uid,
            author: story.author,
            storyId: storyId,
            type: 'story',
            rating: rating,
            actId: id
        };
        try {
            await setDoc(newDocRef, docData);
            const updatedActivities = [...activity, docData]; // Add new activity to existing activities
            dispatch(setActivity(updatedActivities)); 
        } catch (error) {
            console.log('Error adding document:', error);
        }
    };

    const deleteActivity = async (id) => {
        if (!user) return;
        // Reference to the activity collection
        const activityCollection = collection(firestore, `activity`);
        // Query to find the activity document to delete
        const activityQuery = query(activityCollection, where("actId", "==", id));
        const activitySnap = await getDocs(activityQuery);
    
        if (!activitySnap.empty) {
            // Delete the first matching activity document
            const activityDoc = activitySnap.docs[0];
            await deleteDoc(activityDoc.ref);
            
            // Step 2: Update global state by removing the deleted activity
            const updatedActivities = activity.filter(activity => activity.actId !== id);
            dispatch(setActivity(updatedActivities)); // Dispatch the updated activities
        }
    };



    const handleLike = async () => {
        if (!user) return;
    
        const storyRef = doc(firestore, `stories`, storyId);
        const alreadyLiked = story.likes?.find(like => like.username === user.username); // Check if the user has liked the story
        const likeId = new Date().toISOString(); // Unique ID for the like activity
    
        try {
            await runTransaction(firestore, async (transaction) => {
                if (alreadyLiked) {
                    // Remove the like and delete the activity
                    transaction.update(storyRef, {
                        likes: arrayRemove(alreadyLiked) // Remove the like object (which contains username and likeId)
                    });
                    await deleteActivity(alreadyLiked.likeId); // Use the stored likeId to delete the activity
                } else {
                    // Add the new like and store the likeId
                    const newLike = {
                        username: user.username,
                        likeId: likeId // Store the likeId along with the username
                    };
    
                    transaction.update(storyRef, {
                        likes: arrayUnion(newLike) // Add the new like object
                    });
                    await addActivity('liked', null, likeId); // Track the 'liked' activity
                }
            });
    
            // Update local state after the transaction completes
            const updatedLikes = alreadyLiked
                ? story.likes.filter(like => like.username !== user.username)
                : [...(story.likes || []), { username: user.username, likeId }];
    
            const updatedStory = { ...story, likes: updatedLikes };
            setStory(updatedStory);
    
            // Update global stories state
            const updatedStories = stories.map((s) =>
                s.id === storyId ? updatedStory : s
            );
            dispatch(setStories(updatedStories));

        // Dispatch setUsers to update the global users state
        const updatedUsers = users.map(u => u.username === story.username ? { ...u, points: u.points + 1 } : u);
        dispatch(setUsers(updatedUsers));
    
        } catch (error) {
            console.error("Error handling like:", error);
        }
    };
    
    
    

    const handleFavorite = async () => {
        if (!user) return;
        
        try {
        
            const favorites = story.favorites || []; // Ensure favorites array exists
            const userFavorites = user.favorites || []; // Ensure user's favorites array exists
            const isFavorited = favorites.includes(user.uid); // Check if the current user has favorited the story
        
            const storyRef = doc(firestore, `stories`, storyId); // Story reference
            const userFavoritesRef = doc(firestore, `users/${user.username}`); // User profile reference (to store favorites)
        
            if (isFavorited) {
                // Remove the story from the current user's favorites in Firestore
                await updateDoc(storyRef, {
                    favorites: arrayRemove(user.uid)
                });
                
                // Remove the story title from the user's favorites in Firestore
                await updateDoc(userFavoritesRef, {
                    favorites: arrayRemove(story.title) // Remove story title from user's profile
                });
        
                // Remove "favorited" activity
                await deleteActivity(user.username); 
        
            } else {
                // Add the story to the current user's favorites in Firestore
                await updateDoc(storyRef, {
                    favorites: arrayUnion(user.uid)
                });
        
                // Add the story title to the user's favorites in Firestore
                await updateDoc(userFavoritesRef, {
                    favorites: arrayUnion(story.title) // Add story title to user's profile
                });
        
                // Add "favorited" activity
                await addActivity('favorited', null, user.username); // Add the activity
            }
        
            // Step 1: Update the local story state
            const updatedStory = {
                ...story,
                favorites: isFavorited
                    ? favorites.filter(uid => uid !== user.uid) // Remove UID from favorites
                    : [...favorites, user.uid] // Add UID to favorites if not already favorited
            };
            dispatch(setStories(stories.map(s => s.id === storyId ? updatedStory : s))); // Update global stories in Redux
        
            // Step 2: Update the global user state to reflect the favorite change
            const updatedUser = {
                ...user,
                favorites: isFavorited
                    ? userFavorites.filter(title => title !== story.title) // Remove story title from favorites
                    : [...userFavorites, story.title] // Add story title to favorites
            };
            dispatch(setUsers(users.map(u => u.uid === user.uid ? updatedUser : u))); // Update global users in Redux
            dispatch(setUser(updatedUser))
        
        } catch (error) {
            console.error("Error handling favorite: ", error);
            // Handle any errors that occur during the update
        }
    };
    

    

    const handleRating = async (rating) => {
        if (!user || !story) return;
    
        try {
            // Get the ratings array from the story
            const ratings = story.ratings || [];
            const userRating = ratings.find(r => r.user === user.username); // Check if the user has already rated
            const storyRef = doc(firestore, `stories`, storyId); // Firestore reference for the story
            const ratingId = new Date().toISOString(); // Use timestamp as ratingId

            // Step 1: If the user has already rated, remove the old rating
            if (userRating) {
                await updateDoc(storyRef, {
                    ratings: arrayRemove(userRating) // Remove the old rating
                });
                deleteActivity(userRating.ratingId)

            }
    
            // Step 2: Add the new rating to Firestore
    
            const newRating = {
                user: user.username,
                rating: rating,
                ratingId: ratingId // Store ratingId for potential future use
            };
    
            await updateDoc(storyRef, {
                ratings: arrayUnion(newRating) // Add the new rating
            });
    
            // Step 3: Update the local story state with the new ratings
            const updatedRatings = userRating
                ? ratings.map(r => r.user === user.username ? newRating : r) // Replace the old rating with the new one
                : [...ratings, newRating]; // Add the new rating if no previous rating exists
    
            const updatedStory = {
                ...story,
                ratings: updatedRatings // Update the ratings array in the story object
            };
    
            setStory(updatedStory); // Update the local state with the new story data
    
            // Step 4: Update the global stories state in Redux
            const updatedStories = stories.map(s =>
                s.id === storyId ? updatedStory : s
            );
            dispatch(setStories(updatedStories)); // Dispatch updated stories to Redux
    
            // Step 5: Log the "rated" activity
            await addActivity('rated', rating, ratingId); // Add the activity with the ratingId as actId
    
            // Step 6: Update the selected rating in the local state
            setSelectedRating(rating);
    
        } catch (error) {
            console.error("Error handling rating:", error);
            // Handle any errors that occur during the update
        }
    };
    
    

    const filter = new Filter();
    filter.removeWords('butt', 'hell','hells', 'ass')

    const handleCommentSubmit = async () => {
        if (comment.trim() !== "" && user) {
            // Check for inappropriate content in the comment
            if (filter.isProfane(comment)) {
                alert("Your comment contains inappropriate language. Please revise it.");
                return; // Stop the submission
            }

            const cleanComment = filter.clean(comment);
            const commentId = new Date().toISOString(); // Unique ID for the comment

            // New comment object for local state
            const newComment = {
                username: user.username,
                text: cleanComment,
                replies: [],
                commentId,
            };

            // Step 1: Optimistically update local UI state before Firestore
            const updatedStory = {
                ...story,
                comments: [...story.comments, newComment]
            };
            setStory(updatedStory); // Local state
            setComment(""); // Clear comment input
            dispatch(setStories(stories.map(s => (s.id === storyId ? updatedStory : s))));

            try {
                // Step 2: Update Firestore asynchronously
                const storyRef = doc(firestore, `stories`, storyId);
                await updateDoc(storyRef, { comments: arrayUnion(newComment) });
                console.log("Comment added to Firestore");
                await addActivity('commented on', null, commentId); // Activity log
            } catch (error) {
                console.error("Error submitting comment: ", error);
            }
        }
    };

    const handleReplySubmit = async (parentComment) => {
        if (reply.trim() !== "" && user && story) {
            // Check for inappropriate content in the reply
            if (filter.isProfane(reply)) {
                alert("Your reply contains inappropriate language. Please revise it.");
                return; // Stop the submission
            }

            const cleanReply = filter.clean(reply); // Clean the reply text
            const storyRef = doc(firestore, `stories`, storyId);
            const replyId = new Date().toISOString(); // Unique ID for the reply

            // Update the comments locally by adding the reply to the correct parent comment
            const updatedComments = story.comments.map(comment => {
                if (comment.commentId === parentComment.commentId) {
                    return {
                        ...comment,
                        replies: [
                            ...comment.replies,
                            {
                                username: user.username,
                                text: cleanReply,
                                replyId: replyId,
                            }
                        ]
                    };
                }
                return comment;
            });

            // Update Firestore with the new comments
            await updateDoc(storyRef, { comments: updatedComments });

            // Update the local state
            const updatedStory = {
                ...story,
                comments: updatedComments // Update comments with the new reply
            };
            setStory(updatedStory); // Set the updated story in local state
            setReply(""); // Clear the reply input
            setVisibleReplies(prev => {
                // Ensure prev is a Map, or create a new Map if it isn't
                const currentMap = prev instanceof Map ? prev : new Map();
            
                // Set the visibility for the parent comment
                return currentMap.set(parentComment.commentId, true);
            });    
            // Update the global state (Redux)
            const updatedStories = stories.map(s =>
                s.id === storyId ? updatedStory : s
            );
            dispatch(setStories(updatedStories)); // Dispatch updated stories to Redux

            // Add activity for replying to a comment
            await addActivity('replied to a comment on', null, replyId); // Add the reply activity
        }
    };


    const handleDeleteComment = async (commentToDelete) => {
        if (!story || !user) return;
    
        try {
            const storyRef = doc(firestore, `stories`, storyId); // Firestore reference for the story
    
            // Update the comments locally by filtering out the deleted comment
            const updatedComments = story.comments.filter(comment => comment.commentId !== commentToDelete.commentId);
    
            // Update Firestore with the new comments
            await updateDoc(storyRef, { comments: updatedComments });
    
            // Update the local state
            const updatedStory = {
                ...story,
                comments: updatedComments // Update comments after deletion
            };
            setStory(updatedStory); // Set the updated story in local state
    
            // Update the global state (Redux)
            const updatedStories = stories.map(s =>
                s.id === storyId ? updatedStory : s
            );
            dispatch(setStories(updatedStories)); // Dispatch updated stories to Redux
    
            // Delete the corresponding activity by matching the comment action and time
            await deleteActivity(commentToDelete.commentId); // Remove comment activity
    
        } catch (error) {
            console.error("Error deleting comment: ", error);
        }
    };
    
    const handleDeleteReply = async (parentComment, replyToDelete) => {
        if (!story || !user) return;
    
        try {
            const storyRef = doc(firestore, `stories`, storyId); // Firestore reference for the story
    
            // Update the comments locally by filtering out the deleted reply
            const updatedComments = story.comments.map(comment => {
                if (comment.commentId === parentComment.commentId) {
                    return {
                        ...comment,
                        replies: comment.replies.filter(reply => reply.replyId !== replyToDelete.replyId)
                    };
                }
                return comment;
            });
    
            // Update Firestore with the new comments
            await updateDoc(storyRef, { comments: updatedComments });
    
            // Update the local state
            const updatedStory = {
                ...story,
                comments: updatedComments // Update comments after reply deletion
            };
            setStory(updatedStory); // Set the updated story in local state
    
            // Update the global state (Redux)
            const updatedStories = stories.map(s =>
                s.id === storyId ? updatedStory : s
            );
            dispatch(setStories(updatedStories)); // Dispatch updated stories to Redux
    
            // Delete the corresponding activity by matching the reply action and time
            await deleteActivity(replyToDelete.replyId); // Remove reply activity
    
        } catch (error) {
            console.error("Error deleting reply: ", error);
        }
    };
    

    
    

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    const increaseFontSize = () => {
        setFontSize(fontSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize(fontSize > 12 ? fontSize - 2 : fontSize); // Prevent font size from going below 12px
    };


    const toggleComments = (story) => {
        setVisibleComments(prev => {
            const newVisibleComments = new Set(prev);
            if (newVisibleComments.has(story.storyId)) {
                newVisibleComments.delete(story.storyId);
            } else {
                newVisibleComments.add(story.storyId);
            }
            return newVisibleComments;
        });
    };

    const toggleReplies = (comment) => {
        setVisibleReplies(prev => {
            const newVisibleReplies = new Set(prev);
            if (newVisibleReplies.has(comment.commentId)) {
                newVisibleReplies.delete(comment.commentId);
            } else {
                newVisibleReplies.add(comment.commentId);
            }
            return newVisibleReplies;
        });
    };

    

    if (isLoading) {
        return (
            <div className="loading-screen">
                <div className="loading-overlay"></div>
                <img src="/images/favlogotrans.png" alt="Loading..." className="loading-image" loading="lazy"/>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }


    const totalRatings = story.ratings ? story.ratings.length : 0;
    const averageRating = totalRatings > 0 ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / totalRatings).toFixed(1) : 0;


    return (
        <div id='story-page'>
            
            <div id='full-story' className={isDarkMode ? 'dark-mode' : 'light-mode'} style={{ fontSize: `${fontSize}px` }}>
                <div className='story-controls'>
                    <div 
                        onClick={toggleDarkMode}
                        className={isDarkMode ? 'dark-mode-button' : 'light-mode-button'}
                    >
                        {isDarkMode ? "Light Mode" : "Dark Mode"}
                    </div>
                    <div className='font-size-control'>
                        <span id='font-button-minus' onClick={decreaseFontSize}>-</span>
                        <span id='font-label'>Font Size</span>
                        <span id='font-button-plus' onClick={increaseFontSize}>+</span>
                    </div>
                </div>
                    <div id='indyStory'>
                        {user && (user.username !== username) && (
                            <FontAwesomeIcon
                                id='heart'
                                className='favHeart'
                                onClick={handleFavorite}
                                icon={story.favorites?.includes(user.uid) ? faHeartCircleCheck : faHeart}
                            />
                        )}
                        {story.promptId ? <Link to={`/prompt-generators/userPrompts/${story.promptId}`} style={{textDecoration: 'none'}}><div id='story-page-prompt'>{JsonToReact({ jsonString: story.prompt })}</div></Link> :
                        <div id='story-page-prompt'>{JsonToReact({ jsonString: story.prompt })}</div>

                        }
                        
                        {story.flags.length > 0 && (
                            <p style={{ color: 'yellow' }}>
                                Warning! Story has been flagged for:{" "}
                                {story.flags.map((flag, index) => <span key={flag.type || index}>*{flag.type} </span>)}
                            </p>
                        )}
                        {story.contentWarnings.length > 0 && (
                            <p style={{ color: 'red' }}>Warning! Story contains: {story.contentWarnings.map(warning => <span key={warning}> *{warning} </span>)}</p>
                        )}
                        {story.badge && <img alt='story badge'width='250px' src={`../../../images/badges/${story.badge}.png`} />}
                        
                        <h1>{story.title}</h1>
                        <DisplayGenreIcon genres={story.genres} style={{ width: '60px', borderRadius: '35px', margin: '.5rem' }} />
                        <h5>{formatDate(story.time)} / {story.wordCount} words</h5>
                        
                        
                        <p ref={firstParagraphRef} dangerouslySetInnerHTML={{ __html: storyPages[currentPage - 1] }} />

                        {user && showBookmarkButton && (
                            <button className="fixed-bookmark-button" onClick={handleBookmark}>Set Bookmark</button>
                        )}
                    </div>

                {storyPages.length > 1 && (
                <div>
                    <Pagination
                        count={storyPages.length}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                    />
                </div>
                )}
 
                <div className='story-interaction'>
                    <div id='story-rating'>
                            <button className='story-like-button' onClick={handleLike} disabled ={!user || (user && user.username ===username) ? true : false}>
                                <FontAwesomeIcon
                                    icon={story.likes && story.likes.some(like => like.username === user?.username) ? faThumbsUpFilled : faThumbsUpOutline}
                                    className='like-icon'
                                    
                                />
                                {story.likes ? story.likes.length : 0}
                            </button>
                        {story.rateable && [1, 2, 3, 4, 5].map((star) => (
                            <FontAwesomeIcon
                                key={star}
                                icon={faStar}
                                onClick={() => user && user.username !== username && handleRating(star)} // Call handleRating when clicked
                                className={selectedRating >= star ? 'selected' : ''} // Highlight based on selectedRating
                                id='ratings-stars'
                                disabled ={user && user.username ===username ? true : false}
                            />
                        ))}

                        <span className='rating-info'>{averageRating} ({totalRatings})</span>
                        <span className='rating-info'> <FontAwesomeIcon icon={faEye} /> {story.views}</span>
                    </div>
                </div>

                <div className="story-comments-section">
                    <button
                        className={`toggle-story-comments ${visibleComments.has(story.storyId) ? 'active' : ''}`}
                        onClick={() => toggleComments(story)}
                    >
                        <>
                            {story.comments.length} <FontAwesomeIcon icon={faComment} />{" "}
                            {story.comments.reduce((totalReplies, c) => totalReplies + c.replies.length, 0) > 0 && (
                                <>
                                    {story.comments.reduce((totalReplies, c) => totalReplies + c.replies.length, 0)}
                                    <FontAwesomeIcon icon={faComments} />
                                </>
                            )}
                        </>
                    </button>

                    {/* Render comments and comment input if comments are toggled on */}
                    {visibleComments.has(story.storyId) && (
                        <div className="comment-section">
                            <div className="add-comment-section">
                                <input
                                    type="text"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder="Add a comment..."
                                    className="comment-input"
                                    maxLength={3000}
                                />
                                <br/>
                                <button className="story-comment-submit" onClick={handleCommentSubmit}>Submit</button>
                            </div>
                            {/* Render each comment if there are any */}
                            {story.comments && story.comments.length > 0 ? (
                                story.comments.map((comment, idx) => (
                                    <div key={idx} className="story-comment">
                                        <p>
                                            <Link to={`/${comment.username}`} id="commenter-name">
                                                <strong>~{comment.username}</strong>
                                            </Link>:
                                            <br /><br />
                                            <span className="comment-text" dangerouslySetInnerHTML={{ __html: comment.text }}></span>
                                        </p>
                                        <p className="activity-date">{formatDate(comment.commentId)}</p>
                                        <div className="thought-buttons">
                                            {/* Replies toggle button for each comment */}
                                            <div className="comment-toggle">
                                                <button
                                                    className={`toggle-story-replies ${visibleReplies.has(comment.commentId) ? 'active' : ''}`}
                                                    onClick={() => toggleReplies(comment)}
                                                >
                                                    {comment.replies.length} <FontAwesomeIcon icon={faComment} />
                                                </button>
                                            </div>
                                            {/* Delete button for user's own comments */}
                                            <div>
                                                {user && comment.username === user.username && (
                                                    <button className="comment-delete-button" onClick={() => handleDeleteComment(comment)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        {/* Render replies if toggled on for this comment */}
                                        {visibleReplies.has(comment.commentId) && comment.replies && comment.replies.map((reply, replyIdx) => (
                                            <div key={replyIdx} className="story-reply">
                                                <p>
                                                    <Link to={`/${reply.username}`} id="commenter-name">
                                                        <strong>~{reply.username}</strong>
                                                    </Link>:
                                                    <br /><br />
                                                    <span className="comment-text" dangerouslySetInnerHTML={{ __html: reply.text }}></span>
                                                </p>
                                                <div className="thought-buttons">
                                                <p className="activity-date">{formatDate(reply.replyId)}</p>
                                                {/* Delete button for user's own replies */}
                                                {user && reply.username === user.username && (
                                                    <button className="comment-delete-button" onClick={() => handleDeleteReply(comment, reply)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </button>
                                                )}
                                                </div>
                                            </div>
                                        ))}

                                        {/* Reply input section, visible when replies are toggled */}
                                        {visibleReplies.has(comment.commentId) && user && (
                                            <div className="reply-section">
                                                <input
                                                    type="text"
                                                    value={reply}
                                                    onChange={(e) => setReply(e.target.value)}
                                                    placeholder="Add a reply..."
                                                    className="reply-input"
                                                    maxLength={3000}
                                                />
                                                <button className="reply-submit" onClick={() => handleReplySubmit(comment)}>Submit</button>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                // Message for no comments (optional)
                                <p className="no-comments"></p>
                            )}
                            
                        </div>
                    )}
                </div>
                <FlagContent type={'story'} story={story} user={user}/>

                <StoryShare storyTitle={story.title} storyId={storyId} username={username}/>

                <ReadNext currentStory={story} />
                
                {user?.admin && <SelectFeature story={story}/>}

            </div>
        </div>
    );
};
