import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const TotalStats = () => {
    const { users, stories } = useSelector(state => state.app);

    const [totalStats, setTotalStats] = useState({
        users: 0,
        stories: 0,
        likes: 0,
        totalRatings: 0,
        avgRating: 0,
        comments: 0,
        replies: 0,
    });

    const [promptTypeCounts, setPromptTypeCounts] = useState({
        plot: 0,
        character: 0,
        setting: 0,
        random: 0,
        premise: 0,
        usp: 0,
        user: 0,
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const calculateStats = () => {
            let totalUsers = users.length; // Total users
            let totalStories = stories.length; // Total stories
            let totalLikes = 0;
            let totalComments = 0;
            let totalReplies = 0;
            let totalRatings = 0;
            let totalRatingSum = 0;

            // Initialize prompt type counts
            let promptTypeCounts = {
                plot: 0,
                character: 0,
                setting: 0,
                random: 0,
                premise: 0,
                usp: 0,
                user: 0,
            };

            // Loop through stories to calculate additional stats
            stories.forEach(story => {
                // Count likes
                totalLikes += story.likes ? story.likes.length : 0;

                // Count comments
                totalComments += story.comments ? story.comments.length : 0;

                // Count replies within comments
                if (story.comments) {
                    totalReplies += story.comments.reduce((sum, comment) => {
                        return sum + (comment.replies ? comment.replies.length : 0);
                    }, 0);
                }

                // Count ratings
                if (story.ratings) {
                    totalRatings += story.ratings.length;
                    totalRatingSum += story.ratings.reduce((sum, r) => sum + r.rating, 0);
                }

                // Categorize by prompt type
                if (story.promptType && promptTypeCounts.hasOwnProperty(story.promptType)) {
                    promptTypeCounts[story.promptType] += 1;
                }
            });

            const avgRating = totalRatings ? totalRatingSum / totalRatings : 0;

            setTotalStats({
                users: totalUsers,
                stories: totalStories,
                likes: totalLikes,
                totalRatings,
                avgRating,
                comments: totalComments,
                replies: totalReplies,
            });

            setPromptTypeCounts(promptTypeCounts);
            setIsLoading(false);
        };

        calculateStats();
    }, [users, stories]); // Recalculate when users or stories change

    if (isLoading) {
        return (
            <div className="loading-screen">
                <div className="loading-overlay"></div>
                <img src="/images/brand/favlogotrans.png" alt="Loading..." className="loading-image" />
            </div>
        );
    }

    return (
        <div className="total-stats">
            <div className="stats-item">
                <h3>Total Users:</h3>
                <p>{totalStats.users}</p>
            </div>
            <div className="stats-item">
                <h3>Stories:</h3>
                <p>{totalStats.stories}</p>
            </div>
            <div className="stats-item">
                <h3>Likes:</h3>
                <p>{totalStats.likes}</p>
            </div>
            <div className="stats-item">
                <h3>Total Ratings:</h3>
                <p>{totalStats.totalRatings}</p>
            </div>
            <div className="stats-item">
                <h3>Average Rating:</h3>
                <p>{totalStats.avgRating.toFixed(2)}</p>
            </div>
            <div className="stats-item">
                <h3>Comments:</h3>
                <p>{totalStats.comments}</p>
            </div>
            <div className="stats-item">
                <h3>Replies:</h3>
                <p>{totalStats.replies}</p>
            </div>
            <div className="plot-type-stats">
                {Object.entries(promptTypeCounts).map(([promptType, count]) => (
                <div key={promptType} className="stats-item">
                    <h3>{promptType}:</h3>
                    <p>{count}</p>
                </div>
                ))}
            </div>
            
        </div>
    );
};
