import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import { formatDate } from "../../utilities/formatDate";
import Pagination from '@mui/material/Pagination';
import './members.css';
import { setLatestActivity } from "../../utilities/setLatestActivity";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "../../app/appSlice";

export const Members = () => {
    const { users, activity, isLoading, error, user } = useSelector(state=>state.app)
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [currentPage]);

    useEffect(() => {
        if (users.length > 0 && activity.length > 0) {
            const updatedUsers = setLatestActivity({ users, activity });
  
            // Compare old and new users data to avoid unnecessary state updates
            const hasChanges = JSON.stringify(users) !== JSON.stringify(updatedUsers);
  
            // Only dispatch if there are changes
            if (hasChanges) {
                dispatch(setUsers(updatedUsers));
            }
        }
    }, [users, activity, dispatch]);

    const itemsPerPage = 20; // Adjust the number of items per page as needed
    const filteredUsers = users.filter(user =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    const paginatedUsers = filteredUsers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    if (error) {
        return <div>{error}</div>;
    }

    if (!user) {
        navigate('/login');
    }

    return (
        <div className="page">
            {isLoading &&
                <div className="loading-screen">
                    <div className="loading-overlay"></div>
                    <img src="/images/brnad/favlogotrans.png" alt="Loading..." className="loading-image" loading="lazy" />
                </div>}
            <LeftSide />
            <div className="content">
                <header>
                    <h1>Members</h1>
                    
                    
                </header>
                <div className="wide-search" style={{width: '200px', marginBottom: '1rem'}}>
                        <input
                            type="text"
                            placeholder="Search members..."
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="search-input"
                        />
                    </div>
                <div className='users-list'>
                    {paginatedUsers.map(user => (
                        <Link className='username' to={`/${user.username}`} key={user.id}>
                            <div className='user-card'>
                                <img style={{ borderRadius: '5px', backgroundColor: 'rgba(25,28,36,.95)' }} src={user.profileImageUrl} alt="Profile" id='users-img' />
                                <b>~{user.username} </b>
                                {user.penName && <p className="aka">a.k.a</p>}
                                <strong className="penName" >{user.penName}</strong>
                                <p><b>{user.rank}</b> of valor <b>{user.points}</b></p>
                                <p><b>Joined: </b>{formatDate(user.joined)}</p>

                                <p><b>Last Active:</b> {user.latestActivity ? user.latestActivity : 'No activity yet'}</p>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        color="primary"
                        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                    />
                </div>
            </div>
            <RightSide />
        </div>
    );
};
