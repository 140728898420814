import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectUspPrompt, uspGen } from "./uspPromptSlice";
import { setPrompt } from "../writePrompt/writePromptSlice";
import { SavePrompt } from "../savePrompt";
import { CreatePromptContest } from "../createPromptContest";
import { CreatePotd } from "../../admin/createPotd";

export const UspPrompt = () => {
    const {user} = useSelector(state => state.app)
    const prompt = useSelector(selectUspPrompt);
    const dispatch = useDispatch();
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (prompt) {
            setSave(true);
            setStyle({
                backgroundColor: 'rgb(28,28,28)',
                color: 'white',
                padding: '2rem'
            });
        }
    }, [prompt]);

    const handleClick = () => {
        dispatch(uspGen());
        setSave(prevSave => !prevSave);
    };

    const handleWrite = () => {
        dispatch(setPrompt({ type: 'usp', prompt }));
    };

    return (
        <div className="prompt" id="uspPrompt">
            <div className='prompt-subsection' style={style}>
                {prompt}
                <br />
                {save && user && (
                    <>
                        <NavLink to="/writePrompt">
                            <button id="button2" onClick={handleWrite}>Write It</button>
                        </NavLink>
                        {user?.admin && 
                        <>
                            <CreatePromptContest prompt={prompt} promptType='usp'/>
                            <CreatePotd prompt={prompt} promptType='usp'/>
                        </>
                        }
                    </>
                )}
            </div>
            <button onClick={handleClick}>Generate New USP</button>
            {save && user && <SavePrompt prompt={prompt} type="usp" />}
        </div>
    );
};
