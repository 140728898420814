import React, { useState } from 'react';
import './blogBuilder.css';  // Import the CSS file
import { LeftSide } from '../../app/leftSide/leftSide';
import { RightSide } from '../../app/rightSide/rightSide';

function BlogBuilder() {
  const [elements, setElements] = useState([]);
  const [selectedType, setSelectedType] = useState('paragraph');
  const [content, setContent] = useState('');
  const [headerLevel, setHeaderLevel] = useState(1); // To manage header levels (H1 to H6)
  const [alignment, setAlignment] = useState('left'); // Manage text alignment
  const [imageFile, setImageFile] = useState(null);  // Store the uploaded image file
  const [imageCaption, setImageCaption] = useState(''); // Store the image caption
  const [imageError, setImageError] = useState('');  // Error message for image size validation
  const [imageSize, setImageSize] = useState('medium'); // Store selected image size (small, medium, large)

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      const fileSizeMB = file.size / 1024 / 1024; // Convert bytes to MB
      if (fileSizeMB > 1) {
        setImageError('Image size must be less than 1MB.');
        setImageFile(null); // Clear the file input if it exceeds size
      } else {
        setImageError('');  // Clear any previous error
        setImageFile(file);  // Set the selected file
      }
    }
  };

  // Function to handle adding a new element
  const addElement = () => {
    if (content.trim() === '' && selectedType !== 'image') return;

    let elementContent = content;

    // Handle image upload and set its URL if an image is selected
    if (selectedType === 'image' && imageFile) {
      const imageUrl = URL.createObjectURL(imageFile);  // Simulating image URL for now
      elementContent = imageUrl; // Set the image URL
    }

    const newElement = {
      type: selectedType,
      content: elementContent,
      headerLevel,  // Only used for header elements
      alignment,    // Store the alignment for the element
      caption: selectedType === 'image' ? imageCaption : '',  // Store the caption for images
      imageSize,    // Store the size of the image
    };

    setElements([...elements, newElement]);
    setContent(''); // Clear input after adding
    setAlignment('left'); // Reset alignment to left after adding the element
    setImageFile(null); // Reset image file after adding the element
    setImageCaption(''); // Reset image caption after adding the element
    setImageSize('medium'); // Reset image size after adding the element
  };

  // Function to delete an element
  const deleteElement = (index) => {
    const updatedElements = elements.filter((_, i) => i !== index); // Remove element at index
    setElements(updatedElements);
  };

  // Function to render the preview of the blog post
  const renderElement = (element, index) => {
    const style = { textAlign: element.alignment }; // Apply alignment dynamically
  
    // Define image sizes
    const imageSizeClasses = {
      small: 'image-small',
      medium: 'image-medium',
      large: 'image-large',
    };
  
    const imageAlignmentStyles = {
      left: { justifySelf: 'start' },
      center: { justifySelf: 'center' },
      right: { justifySelf: 'end' },
    };
  
    // Combine the two style objects
    const combinedStyle = {
      ...style, // Default style (text alignment)
      ...imageAlignmentStyles[element.alignment], // Alignment for the image
    };
  
    switch (element.type) {
      case 'header':
        const HeaderTag = `h${element.headerLevel}`; // Dynamically set the header tag
        return (
          <div key={index} style={combinedStyle} className="element-container">
            <HeaderTag>{element.content}</HeaderTag>
            <button className="blog-builder-delete-button" onClick={() => deleteElement(index)}>X</button>
          </div>
        );
      case 'paragraph':
        return (
          <div key={index} style={combinedStyle} className="element-container">
            <p>
              {element.content.includes('http') ? (
                <a href={element.content} target="_blank" rel="noopener noreferrer">
                  {element.content}
                </a>
              ) : (
                element.content
              )}
            </p>
            <button className="delete-button" onClick={() => deleteElement(index)}>X</button>
          </div>
        );
      case 'image':
        return (
          <div key={index} style={combinedStyle} className="element-container image-container">
            <img
              src={element.content}
              alt="User added"
              className={`blog-builder-image ${imageSizeClasses[element.imageSize]}`}
            />
            {element.caption && <p className="image-caption">{element.caption}</p>}
            <button className="delete-button" onClick={() => deleteElement(index)}>X</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="page">
      <LeftSide />
      <div className="content">
        <div className="blog-builder-content">
          <header><h1>Blog Post Builder</h1></header>
          
          {/* Blog Preview */}
          <div className="blog-builder-preview">
            {elements.length === 0 ? (
              <p>Start adding elements to build your blog post!</p>
            ) : (
              <div className="image-flex-container">
                {elements.map((element, index) => renderElement(element, index))}
              </div>
            )}
          </div>
          
          {/* Control Panel */}
          <div className="blog-builder-control-panel">
            <select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className="blog-builder-select"
            >
              <option value="paragraph">Paragraph</option>
              <option value="header">Header</option>
              <option value="image">Image Upload</option>
            </select>
            
            {/* If the user selects "Header", show the header level dropdown */}
            {selectedType === 'header' && (
              <select
                value={headerLevel}
                onChange={(e) => setHeaderLevel(Number(e.target.value))}
                className="blog-builder-select"
              >
                <option value={1}>H1</option>
                <option value={2}>H2</option>
                <option value={3}>H3</option>
                <option value={4}>H4</option>
                <option value={5}>H5</option>
                <option value={6}>H6</option>
              </select>
            )}
            
            {/* Text Alignment Selector */}
            <select
              value={alignment}
              onChange={(e) => setAlignment(e.target.value)}
              className="blog-builder-select"
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
            
            {/* Conditionally render the text input for content */}
            {selectedType !== 'image' && (
              <input
                type="text"
                placeholder={`Enter ${selectedType} content`}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="blog-builder-input"
              />
            )}

            {/* If the user selects "Image", show image upload field, size selector, and caption input */}
            {selectedType === 'image' && (
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="blog-builder-input"
                />
                <select
                  value={imageSize}
                  onChange={(e) => setImageSize(e.target.value)}
                  className="blog-builder-select"
                >
                  <option value="small">Small</option>
                  <option value="medium">Medium</option>
                  <option value="large">Large</option>
                </select>
                <input
                  type="text"
                  placeholder="Enter image caption"
                  value={imageCaption}
                  onChange={(e) => setImageCaption(e.target.value)}
                  className="blog-builder-input"
                />
                {imageError && <span className="error-message">{imageError}</span>}
              </div>
            )}

            {/* Add element button */}
            <button onClick={addElement} className="blog-builder-button">Add Element</button>
          </div>
          
        </div>
      </div>
      <RightSide />
    </div>
  );
}

export default BlogBuilder;
