import React from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import './prompts.css'



export const Prompts = () => {
  //window.onload = function() {
    // similar behavior as clicking on a link
  //window.location.href = "http://localhost:3000/prompt-generators/promptHome";
//}
    return (
        <div className='page'>

          <div className='content' id='prompts'>
          <header>
            <Link to={'/prompt-generators'} className='link'><h1>Super Random Story Prompt Generators</h1></Link>
          </header>
          <nav className='lineNav'>
              <NavLink to={'plotPrompt'} className='link'>Plot</NavLink>
              <NavLink to={'characterPrompt'} className='link'>Character</NavLink>
              <NavLink to={'settingPrompt'} className='link'>Setting</NavLink>
              <NavLink to={'randomPrompt'} className='link'>Random Story Elements</NavLink>
              <NavLink to={'premisePrompt'} className='link'>Story Premise</NavLink>
              <NavLink to={'uspPrompt'} className='link'>USP</NavLink>
              {<NavLink to={'userPrompts'} className='link'>User</NavLink>}
          </nav>

              <Outlet />
        </div>

        </div>
        
    )

}