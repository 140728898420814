import React, { useEffect, useRef } from "react";
import { Outlet, useParams } from 'react-router-dom';
import { ProfHeader } from "./profHeader";
import './profile.css';
import { useSelector } from "react-redux";

export const Profile = () => {

  const { users, stories, activity, following, isLoading, error, user } = useSelector(state=>state.app);
  const {username} = useParams();
  const profile = users.find(user => user.username === username);

  const previousUsername = useRef(username); 
  const isInitialRender = useRef(true); // This ref will track if it's the first render

  useEffect(() => {
    if (previousUsername.current !== username || isInitialRender.current) {
      // Scroll to the top if the profile has changed or it's the initial render
      window.scrollTo(0, 0);
      isInitialRender.current = false;
    }
    previousUsername.current = username; // Update previous username for the next check
  }, [username]);
  
  return (
    <div className='page'>
      {users.length && <div className='content' id='prof-page'>
        <ProfHeader
            user={user}
            users={users} 
            following={following} 
            profile={profile} 
            activity = {activity}
            
          />        
          <Outlet context={ {user, users, stories, activity, following, isLoading, error, profile} } />
      </div>}
    </div>
  );
};
