import React, { useEffect } from "react";
import './blog.css'

export const BlogPost1 = () => {
    useEffect(() => {
        window.scroll(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div className='blogPost'>
            <div className='blogLeft'>
                <img
                    className='blogImage'
                    src='/images/assortedpics/amandine-bataille-5W78I6Jvbjw-unsplash.jpg'
                    alt='Wizard writing desk'
                />
                <p className='blogDate'>March 1, 2024</p>
                <div className='blogNav'>
                    <h4>Contents</h4>
                    <ul>
                        <li><a href='#all-about'>What It’s All About</a></li>
                        <li><a href='#main-features'>Main Features</a></li>
                        <li><a href='#what-to-expect'>What to Expect</a></li>
                    </ul>
                </div>
            </div>

            <div className='blogTop'>
                <h1 className='blogTitle'>Welcome to Writician!</h1>
                <div className='subtitle'>
                    <p>
                        Congratulations on being among the first to join us at Writician, a new platform built exclusively for creative writers! Our mission is to provide a supportive space where writers of all backgrounds can collaborate, create, and grow. Here, we aim to foster creativity, sharpen writing skills, and bring your unique stories to life.
                    </p>
                </div>
            </div>

            <div className='blogSection'>
                <h2 id='all-about'>What It’s All About</h2>
                <p>
                    Writician is all about celebrating the art of creative writing. Whether you're a seasoned writer or just getting started, we’re here to help you expand your ideas and complete them with confidence. From generating ideas to polishing your final draft, we want to help bring more original, compelling, and authentic stories into the world.
                </p>

                <h2 id='main-features'>Main Features</h2>
                <p>
                    We have an array of features designed to support and enhance your writing journey:
                </p>
                <ul>
                    <li>✨ <strong>Prompt Generators:</strong> Jumpstart your creativity with a variety of handcrafted writing prompts designed to transform you into a well-rounded and versatile writer.</li>
                    <li>✏️ <strong>Write Your Idea:</strong> This walk-through offers a step-by-step approach to developing your idea into a fully realized story.</li>
                    <li>📝 <strong>Story Submissions:</strong> Share your work with the community and receive feedback from fellow writers.</li>
                    <li>💬 <strong>Community Interactions:</strong> With comment sections and user profiles, you can connect with others, exchange ideas, and build your writing network.</li>
                    <li>🏆 <strong>Writing Contests:</strong> Participate in daily, weekly, and monthly contests to challenge yourself and showcase your talent.</li>
                    <li>📚 <strong>Forum:</strong> Engage in discussions on all things writing, from craft tips to character development, and connect with fellow writers on our interactive forums.</li>
                </ul>

                <h2 id='what-to-expect'>What to Expect</h2>
                <p>
                    As Writician grows, we’re excited to bring even more features and opportunities for creative growth, including:
                </p>
                <ul>
                    <li>🕵️‍♀️ <strong>Critique Groups:</strong> Join small groups to give and receive constructive feedback, helping each other improve and refine your work.</li>
                    <li>🌐 <strong>Shared Universes:</strong> Contribute to community-driven worlds and shared story settings, adding your unique voice to a collective narrative.</li>
                    <li>🎲 <strong>Writing Games:</strong> Take part in fun, creativity-boosting games that help you experiment with words, challenge writer’s block, and have a great time with fellow writers.</li>
                    <li>🎓 <strong>Educational Resources:</strong> We’re working on articles, tutorials, and workshops to help you continuously improve your craft.</li>
                    <li>🧙‍♂️ <strong>And More:</strong> Look out for new and innovative ways to connect with other writers, get inspired, and make progress on your creative projects!</li>
                </ul>

                <p>Thank you for joining us at Writician. We’re thrilled to be on this journey with you and can’t wait to see the incredible stories you’ll bring to life here!</p>

                <img
                    alt='Writician logo'
                    src='/images/brand/full-logo-trans.png'
                    width='300px'
                    style={{ marginTop: "20px" }}
                />
            </div>
        </div>
    );
};
