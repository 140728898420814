import React, { useEffect } from "react";
import './blog.css'

export const BlogPost3 = () => {
    useEffect(() => {
        window.scroll(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div className='blogPost'>
            <div className='blogLeft'>
                <img
                    className='blogImage'
                    src='/images/magic.jpg'
                    alt='Magical book'
                />
                <p className='blogDate'>Nov 12, 2024</p>
                <div className='blogNav'>
                    <h4>Contents</h4>
                    <ul>
                        <li><a href='#magical-exercise'>The Magical Abilities Exercise</a></li>
                        <li><a href='#reflect-results'>Reflecting on Your World-Building</a></li>
                    </ul>
                </div>
            </div>

            <div className='blogTop'>
                <h1 className='blogTitle'>Creative Writing Exercise #1: Magical Abilities</h1>
                <div className='subtitle'>
                    <p>Explore world-building through magic systems and their role in storytelling.</p>
                </div>
            </div>

            <div className='blogSection'>
                <h2 id='magical-exercise'>The Magical Abilities Exercise</h2>
                <p>
                    Imagine you’re building a fantasy world. The possibilities are endless, and one of the most exciting elements is how magic works in your world. Does magic come from the land itself, ancient artifacts, or powerful beings? Who can access it, and are there limits to its use? These choices shape not just the magic system, but the entire society, culture, and conflicts in your world.
                </p>
                <p>
                    <strong>Try this:</strong> Take a moment to build your own magical system. Start by answering these questions:
                </p>
                <ul>
                    <li>What is the source of magic? (e.g., elemental forces, divine beings, rare resources)</li>
                    <li>Who can wield magic, and how do they access it? (e.g., through education, birthright, or rituals)</li>
                    <li>What are the limits or rules of your magic system? (e.g., certain abilities are forbidden, magic comes with a cost)</li>
                    <li>What consequences or challenges come with using magic? (e.g., social stigma, physical toll, political power)</li>
                </ul>
                <p>
                    Write down your answers and think about how these details affect the way people live and interact in your world. How does magic impact the daily lives of your characters? Does it create a sense of wonder, or does it come with danger and consequence?
                </p>

                <h2 id='reflect-results'>Reflecting on Your World-Building</h2>
                <p>
                    Now that you’ve designed your magic system, let’s take a step back and reflect on the process. What did you learn about your world? Did you feel more creative as you allowed the magic system to emerge organically, or did you feel the need for more structure? How did you feel about the impact of magic on the world you created? Consider the following questions:
                </p>
                <ul>
                    <li>What role does magic play in the world you’ve created? Is it a tool for empowerment, control, or something else entirely?</li>
                    <li>How does magic shape your characters' relationships and the conflicts they face? Are they empowered by magic, or is it something they have to overcome?</li>
                    <li>Does your magic system have ethical or moral implications? What are the consequences of using it, and who bears those consequences?</li>
                </ul>
                <p>
                    After thinking through these questions, take a moment to write a short scene (around 500 words) where a character uses magic. Consider how the magic feels within the world you’ve created. How does the character interact with it, and what are the immediate results of their use? This scene will help you see how magic functions in your world and deepen your understanding of its role in the narrative.
                </p>

                <p>
                    Once you’ve finished, reflect on the scene you’ve written:
                </p>
                <ul>
                    <li>Did your character’s use of magic feel natural within the world? Or did it create new opportunities for conflict or surprise?</li>
                    <li>How did the magic system influence the character’s decisions, actions, and relationships? Were there limitations that they had to work around?</li>
                </ul>
                <p>
                    Magic is a powerful tool for storytelling, and by developing a well-thought-out magic system, you’ve created a world where every spell, action, and consequence holds weight. This exercise is not just about creating fantastical powers; it’s about building a world where magic is an integral part of the story.
                </p>

                <p>
                    <strong>What Does This Mean for You?</strong> Through this exercise, you’ve learned that world-building and magical systems are essential elements in creating a rich, immersive world. Your magic system will shape the dynamics of your story, influence character motivations, and drive conflicts. Whether it’s a source of power, danger, or moral dilemma, your magic system should feel like a natural extension of the world you’ve built.
                </p>

                <p>
                    Reflecting on your choices will help you see the connections between magic, society, and character development. By understanding how your magical world functions, you can now move forward with confidence, knowing that the magic in your world isn’t just a tool, but a driving force in your narrative.
                </p>

                <img
                    alt='Writician logo'
                    src='/images/brand/full-logo-trans.png'
                    width='300px'
                    style={{ marginTop: "20px" }}
                />
            </div>
        </div>
    );
};
