import React, { useEffect } from "react";
import './blog.css'

export const BlogPost2 = () => {
    useEffect(() => {
        window.scroll(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div className='blogPost'>
            <div className='blogLeft'>
                <img
                    className='blogImage'
                    src='/images/blog2.png'
                    alt='Garden next to a building'
                />
                <p className='blogDate'>Nov 12, 2024</p>
                <div className='blogNav'>
                    <h4>Contents</h4>
                    <ul>
                        <li><a href='#gardener-exercise'>The Gardener Exercise</a></li>
                        <li><a href='#architect-exercise'>The Architect Exercise</a></li>
                        <li><a href='#reflect-results'>Reflecting on Your Experience</a></li>
                    </ul>
                </div>
            </div>

            <div className='blogTop'>
                <h1 className='blogTitle'>Are You a Gardener or an Architect?</h1>
                <div className='subtitle'>
                    <p>Try two different approaches to writing and explore which one resonates with you.</p>
                </div>
            </div>

            <div className='blogSection'>
                <h2 id='gardener-exercise'>The Gardener Exercise</h2>
                <p>
                    Let’s start with an exercise. Imagine you’re planting a seed. You don’t know exactly what it will become, but you’re excited to see where it grows. This approach to writing is all about letting your story unfold naturally, without pre-planned structure or rigid outlines. Instead, you allow the characters, setting, and plot to emerge on their own as you write.
                </p>
                <p>
                    <strong>Try this:</strong> Begin by writing the first 500 words of a story, but don’t worry about what’s going to happen. Don’t plan ahead. Just let the characters and ideas evolve as you go, discovering where the story wants to take you. Let the process feel organic and free.
                </p>

                <h2 id='architect-exercise'>The Architect Exercise</h2>
                <p>
                    Now, let’s try something different. In this approach, you’re the architect. You begin with a detailed plan, sketching out every part of the story before you begin writing. Every character, plot twist, and setting is carefully thought out, creating a clear blueprint for your story. It’s about structure, preparation, and knowing exactly where the story is headed from the start.
                </p>
                <p>
                    <strong>Try this:</strong> Write an outline for a story. Plan out the major plot points, character arcs, and key events. Be specific. Once your outline is complete, write the first 500 words of the story, following your plan exactly as you’ve mapped it out.
                </p>

                <h2 id='reflect-results'>Reflecting on Your Experience</h2>
                <p>
                    Now that you’ve tried both approaches, take a moment to reflect. How did each exercise feel? Did one method feel more comfortable or natural than the other? Or did you find challenges in both? Think about the experience of writing in each way:
                </p>
                <ul>
                    <li>Did the Gardener approach allow you to be more creative, or did you feel lost without a plan?</li>
                    <li>Did the Architect approach give you confidence and clarity, or did it feel too rigid or restrictive?</li>
                </ul>
                <p>
                    After reflecting on these experiences, take the next step. Now, write a 1000-word story, but use both methods within the same piece. Start with the Gardener approach: write the first 500 words freely, allowing the story to unfold as you go. Then, switch to the Architect approach for the next 500 words: stop and plan the remainder of your story before continuing to write.
                </p>
                <p>
                    As you write, think about how each method shapes the story. Where did the Gardener approach take you? What ideas or twists emerged that you weren’t expecting? And how did the Architect method help you shape the direction of the second half of your story? Were you able to maintain creative energy while following a detailed plan, or did it feel like the story lost some of its spark?
                </p>

                <p>
                    Once you’ve finished your 1000 words, reflect again. What did you notice about your writing process? Which approach felt more comfortable, and which one challenged you? The two methods represent different ways to approach creativity — one more spontaneous and organic, the other more structured and deliberate.
                </p>

                <p>
                    <strong>What Do These Exercises Mean?</strong> After trying both exercises, you may have noticed that you gravitate toward one method more than the other. This is a key insight into your natural writing style. But here’s the interesting part: both methods have value, and neither one is “better” than the other. The Gardener method may lead to unexpected discoveries, while the Architect method gives you clarity and focus. You can use either approach depending on your needs and the type of story you want to tell.
                </p>

                <p>
                    Many writers find that blending both methods works best. Perhaps you start with an outline (Architect), but then leave room for surprises and changes as you write (Gardener). Or, you might begin writing freely, and later decide to create a more structured outline to guide the rest of the process. The key is to find what works for you and embrace it.
                </p>

                <p>
                    Ultimately, writing is a deeply personal and creative process. Don’t be afraid to experiment with both methods and see where they lead you. The more you explore, the more you’ll learn about your unique approach to storytelling.
                </p>

                <img
                    alt='Writician logo'
                    src='/images/brand/full-logo-trans.png'
                    width='300px'
                    style={{ marginTop: "20px" }}
                />
            </div>
        </div>
    );
};
