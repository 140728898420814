import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEye, faHeart, faStar, faThumbsUp as faThumbsUpFilled } from "@fortawesome/free-solid-svg-icons";
import Pagination from '@mui/material/Pagination';
import parseAndExtractText from "../../utilities/parseAndExtractJson";
import { DisplayGenreIcon } from "../../utilities/displayGenreIcon";
import JsonToReact from "../../utilities/jsonToReact";
import './allStories.css';
import { formatDate } from "../../utilities/formatDate";

export const AllStories = () => {
    const { stories } = useOutletContext(); 
    const [filteredStories, setFilteredStories] = useState([]);
    const [filter, setFilter] = useState("mostRecent");
    const [genre, setGenre] = useState("");
    const [promptType, setPromptType] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 16;
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },[currentPage])
    // Debounce search query input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 500);
        return () => clearTimeout(handler);
    }, [searchQuery]);

    // Apply filters whenever debouncedSearchQuery or other filters change
    const applyFilters = useCallback(() => {
        let filteredStories = [...stories];

        if (genre) {
            filteredStories = filteredStories.filter(story => story.genres?.includes(genre));
        }

        if (promptType) {
            filteredStories = filteredStories.filter(story => story.promptType === promptType);
        }

        if (debouncedSearchQuery) {
            const query = debouncedSearchQuery.toLowerCase();
            filteredStories = filteredStories.filter(
                story =>
                    story.title.toLowerCase().includes(query) ||
                    parseAndExtractText(story.prompt).toLowerCase().includes(query) ||
                    story.story.toLowerCase().includes(query) ||
                    story.author.toLowerCase().includes(query)
            );
        }

        switch (filter) {
            case "highestRated":
                filteredStories = filteredStories.filter(story => story.ratings?.length >= 10);
                filteredStories.sort((a, b) => {
                    const aRating = a.ratings?.reduce((sum, r) => sum + r.rating, 0) / a.ratings.length || 0;
                    const bRating = b.ratings?.reduce((sum, r) => sum + r.rating, 0) / b.ratings.length || 0;
                    return bRating - aRating;
                });
                break;
            case "mostLiked":
                filteredStories.sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0));
                break;
            case "mostRecent":
            default:
                filteredStories.sort((a, b) => new Date(b.time) - new Date(a.time));
                break;
        }

        setTotalPages(Math.ceil(filteredStories.length / itemsPerPage));
        const startIndex = (currentPage - 1) * itemsPerPage;
        setFilteredStories(filteredStories.slice(startIndex, startIndex + itemsPerPage));
    }, [stories, currentPage, debouncedSearchQuery, filter, genre, promptType]);

    // Run filters whenever dependencies change
    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    const handleChangePage = (event, value) => setCurrentPage(value);

    const handleStoryClick = (story) => navigate(`/${story.user}/${story.id}`);

    const genreOptions = [
        "science fiction", "fantasy", "mystery", "thriller", "adventure", "horror",
        "historical fiction", "western", "literary", "romance", "dystopian", "steampunk",
        "space opera", "first contact", "post-apocalyptic", "alternative history",
        "epic", "urban fantasy", "magical realism", "cyberpunk", "superhero", "satire", "heist", "spy"
    ];

    return (
        <div className="page">
            <div className="content">
                <header><h1>The Library</h1></header>
                <div className="filter-section">
                    <div className="search-forum">
                        <input
                            type="text"
                            placeholder="Search keywords..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    <select value={filter} onChange={(e) => setFilter(e.target.value)} className="filter-select">
                        <option value="mostRecent">Most Recent</option>
                        <option value="highestRated">Highest Rated</option>
                        <option value="mostLiked">Most Liked</option>
                    </select>
                    <select value={genre} onChange={(e) => setGenre(e.target.value)} className="genre-select">
                        <option value="">All Genres</option>
                        {genreOptions.map((genre) => (
                            <option key={genre} value={genre}>{genre.charAt(0).toUpperCase() + genre.slice(1)}</option>
                        ))}
                    </select>
                    <select value={promptType} onChange={(e) => setPromptType(e.target.value)} className="filter-select">
                        <option value="">All Prompt Types</option>
                        <option value="plot">Plot</option>
                        <option value="character">Character</option>
                        <option value="setting">Setting</option>
                        <option value="random">Random Elements</option>
                        <option value="premise">Story Premise</option>
                        <option value="usp">USP</option>
                    </select>
                </div>

                <div className="stories-list">
                    {filteredStories.length === 0 ? (
                        <div>No stories available</div>
                    ) : (
                        filteredStories.map((story, index) => (
                            <div key={index} className="all-story" onClick={() => handleStoryClick(story)}>
                                <div className="story-details">
                                    <div className="all-title-author-genres">
                                        <strong className="storyTitle">{story.title}</strong>
                                        <strong>{story.author}</strong>
                                        <DisplayGenreIcon genres={story.genres} style={{ width: '30px', borderRadius: '25px', marginLeft: '.5rem' }} />
                                    </div>
                                    <div className="all-story-prompt"> {JsonToReact({jsonString: story.prompt, omitSpan: true})}</div>
                                    <p className="snippet"><i>{story.story.substring(0, 340)}...</i></p>
                                </div>
                                <div className='story-interaction'>
                            <div>
                                <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {story.likes ? story.likes.length : 0}</span>
                                {story.rateable && <span><FontAwesomeIcon id='star' icon={faStar} /> {story.ratings ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length).toFixed(1) : 0} ({story.ratings ? story.ratings.length : 0})</span>}
                                <span><FontAwesomeIcon id='comment' icon={faComment} /> {story.comments ? story.comments.length : 0}</span>
                                <span><FontAwesomeIcon id='heart' icon={faHeart} /> {story.favorites ? story.favorites.length : 0}</span>
                                <span><FontAwesomeIcon id='eye' icon={faEye} /> {story.views}</span>
                            </div>
                            <div>
                                <span>{formatDate(story.time)}</span>
                            </div>
                        </div>
                            </div>
                        ))
                    )}
                </div>

                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                        className="pagination"
                    />
                )}
            </div>
        </div>
    );
};
