import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Pagination } from '@mui/material';
import './follow.css';
import { useSelector } from "react-redux";

export const Following = () => {
    const { users, following } = useSelector(state => state.app);
    const { username } = useParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    // Memoize follower profile list to avoid unnecessary recalculations
    const profiles = useMemo(() => {
        const followeds = following
            .filter(f => f.follower === username)
            .map(f => users.find(u => u.username === f.followed)) // Map directly to user profiles
            .filter(Boolean); // Remove any undefined profiles (in case some usernames are missing)
        
        return followeds;
    }, [following, users, username]);

    // Filter users based on search query and memoize the result
    const filteredUsers = useMemo(() => 
        profiles.filter(user =>
            user.username.toLowerCase().includes(searchQuery.toLowerCase())
        ),
    [profiles, searchQuery]);

    // Paginate users
    const paginatedUsers = useMemo(() =>
        filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [filteredUsers, currentPage]);

    // Calculate total pages
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    // Handlers
    const handlePageChange = (event, value) => setCurrentPage(value);
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    return (
        <div className="follow-container">
            <input
                type="text"
                placeholder="Search users..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input"
            />
            
            {filteredUsers.length === 0 ? (
                <p>No users found.</p>
            ) : (
                <>
                    <div className="follow-list">
                        {paginatedUsers.map(user => (
                            <div className="following-info" key={user.username}>
                                <img
                                    style={{ width: '50px', height: '50px', borderRadius: '5px', backgroundColor: 'rgb(18, 18, 18)' }}
                                    src={user.profileImageUrl}
                                    alt="Profile"
                                    loading="lazy"
                                />
                                <Link to={`/${user.username}`} className="follow-user-link">
                                    <div className="leaderboard-sub-info">
                                        <b>~{user.username}</b>
                                        {user.penName && <><br /><i>{user.penName}</i></>}
                                        <br/>
                                        <span className="prof-location">{user.location}</span>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                    {totalPages > 1 && (
                        <div className="pagination">
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
