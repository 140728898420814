import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { useSelector } from "react-redux";

export const PageViews = () => {
    const [pageViews, setPageViews] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [activeUsers, setActiveUsers] = useState(0);
    const [timeFrame, setTimeFrame] = useState("daily");
    const {users, activity} = useSelector(state=>state.app);

    const usernames = users.map(user => user.username);
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const monthYear = `${month}-${year}`; // Adjusted to match the PageViewTracker structure

    

    useEffect(() => {

        const getActiveUsersCount = async (timePeriod) => {
            let activeUsersSet = new Set();
        
            // Loop through the activity array to find users active in the given time period
            activity.forEach((act) => {
                const activityTime = new Date(act.time); // Assume each activity object has a `time` field
                const username = act.username; // Assume each activity object has a `username` field
        
                if (activityTime > timePeriod && username) {
                    activeUsersSet.add(username);
                }
            });
        
            return activeUsersSet.size; // Return the number of unique active users
        };

        const fetchAndCategorizeData = async () => {
            try {
                let pageViewsCount = {
                    profile: 0,
                    story: 0,
                    contests: 0,
                    forum: 0,
                    userPrompts: 0,
                    potds: 0,
                    other: {}
                };
                let totalStoryViews = 0;

                // Fetch page views from the correct month-year collection
                const pageViewsSnapshot = await getDocs(collection(firestore, `${monthYear}`));

                pageViewsSnapshot.forEach(doc => {
                    const path = doc.data().path;
                    if (path) {
                        const segments = path.split('/');
                        const isProfilePath = usernames.includes(segments[1]) // Adjust based on path logic
                        const isStoryPath = segments.length === 3 && /^[a-zA-Z0-9]{20}$/.test(segments[2]);
                        const isContestPath = segments.length === 3 && segments[1] === 'contests';
                        const isForumPath = /forum#[a-zA-Z0-9]{20}$/.test(path); // Identifying forum paths
                        const isUserPromptPath = path.startsWith('/prompt-generators/userPrompts/') &&
                        /^[a-zA-Z0-9]{20}$/.test(segments[3]);
                        const isPotdPath = path.startsWith('/potd/') &&
                        /^[a-zA-Z0-9]{20}$/.test(segments[2]);

                        if (isProfilePath) {
                            if (isStoryPath) {
                                pageViewsCount.story += 1;
                                totalStoryViews += 1;
                            } else {
                                pageViewsCount.profile += 1;
                            }
                        } else if (isContestPath) {
                            pageViewsCount.contests += 1;
                        } else if (isForumPath) {
                            pageViewsCount.forum += 1; // Increment forum views
                        } else if (isUserPromptPath){
                            pageViewsCount.userPrompts += 1
                        } else if (isPotdPath) {
                            pageViewsCount.potds += 1
                        } else {
                            const lastSegment = segments.pop() || "Home";
                            pageViewsCount.other[lastSegment] = (pageViewsCount.other[lastSegment] || 0) + 1;
                        }
                    }
                });

                setPageViews({
                    profile: pageViewsCount.profile,
                    totalStoryViews: totalStoryViews,
                    contests: pageViewsCount.contests,
                    forum: pageViewsCount.forum,
                    userPrompts: pageViewsCount.userPrompts,
                    potds: pageViewsCount.potds,
                    other: pageViewsCount.other
                });

                

                // Fetch and categorize active users based on selected time frame
                const timePeriod = getTimePeriod(timeFrame);
                const activeUsersCount = await getActiveUsersCount(timePeriod);
                setActiveUsers(activeUsersCount);

                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching and categorizing data:", error);
                setIsLoading(false);
            }
        };

        fetchAndCategorizeData();
    }, [timeFrame, monthYear, pageViews, usernames, activity]);

    const getTimePeriod = (timeFrame) => {
        const now = new Date();
        if (timeFrame === "daily") {
            return new Date(now.getFullYear(), now.getMonth(), now.getDate());
        } else if (timeFrame === "weekly") {
            const firstDayOfWeek = now.getDate() - now.getDay();
            return new Date(now.getFullYear(), now.getMonth(), firstDayOfWeek);
        } else if (timeFrame === "monthly") {
            return new Date(now.getFullYear(), now.getMonth(), 1);
        }
    };

    

    if (isLoading) {
        return (
            <div className="loading-screen">
                <div className="loading-overlay"></div>
                <img src="/images/brand/favlogotrans.png" alt="Loading..." className="loading-image" />
            </div>
        );
    }

    return (
        <div className="page-views">
            <h3>Active Users <label>
                <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                </select>
            </label>: {activeUsers}</h3>
            
            <h4>Profile Views:</h4>
            <p>{pageViews.profile} views</p>
            <h4>Total Story Views:</h4>
            <p>{pageViews.totalStoryViews} views</p>
            <h4>Contest Views:</h4>
            <p>{pageViews.contests} views</p>
            <h4>Forum Views:</h4>
            <p>{pageViews.forum} views</p>
            <h4>User Prompt Views:</h4>
            <p>{pageViews.userPrompts} views</p>
            <h4>Potd Views:</h4>
            <p>{pageViews.potds} views</p>
            <h4>Other Views:</h4>
            {Object.entries(pageViews.other).map(([path, count]) => (
                <div key={path}>
                    <p>{path}: {count} views</p>
                </div>
            ))}
        </div>
    );
};
