import React from "react"
import { Link, Outlet } from "react-router-dom";

export const Blog = () => {

    return(
        <div className='page'>
            <div className = 'content'>
                <header>
                <Link className='link' to={'/blog'}><h1 id='blogHeader'>The Writician Blog</h1></Link>
                </header>
                <Outlet/>
            </div>
        </div>
        
    )
}
