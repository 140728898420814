import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { doSignOut } from "../../firebase/auth";
import './nav.css';
import { setUser } from "../appSlice";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "../../pages/home/search/search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBlog, faBook, faCalendar, faChevronDown, faChevronUp, faHouse, faPen, faUser } from "@fortawesome/free-solid-svg-icons";

export const Nav = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCommunityDropdownOpen, setIsCommunityDropdownOpen] = useState(false);
  const [isWriteDropdownOpen, setIsWriteDropdownOpen] = useState(false);

  const user = useSelector(state =>state.app.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);  // Reappear on scroll up or if near the top
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible,]);

  // Toggle dropdown for the user menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsCommunityDropdownOpen(false);
    setIsWriteDropdownOpen(false);
  };
  
  const toggleCommunityDropdown = () => {
    setIsCommunityDropdownOpen(!isCommunityDropdownOpen);
    setIsDropdownOpen(false);
    setIsWriteDropdownOpen(false);
  };
  
  const toggleWriteDropdown = () => {
    setIsWriteDropdownOpen(!isWriteDropdownOpen);
    setIsDropdownOpen(false);
    setIsCommunityDropdownOpen(false);
  };
  

  

  const handleSignOut = () => {
    doSignOut().then(() => {
        dispatch(setUser(null)); // Set user in global state to null
    }).catch(error => {
        console.error("Error during sign out:", error);
    });
};

  const isActive = (paths) => paths.some(path => location.pathname.includes(path));

  return (
    <div>
      <nav className='nav'>
        <div id='logos'>
          <Link to={'/'}><img alt='site-logo' id='favLogo' src='/images/brand/favlogotrans.png' /></Link>
          <Link to={'/'}><img alt='logo' id='Wlogo' src='/images/brand/writician-name-white.png' /></Link>

        </div>
        <ul className="nav-list">
          <NavLink to={`stories`} className='navLink' >
          <div className="nav-group">
                  <div className="nav-pair">
                    <div><FontAwesomeIcon icon={faBook}/></div>
                    <div>Read</div>
                  </div>
                </div>
          </NavLink>
          
          <li className={`navLink write-dropdown ${isActive(['/prompt-generators', '/get-idea', '/write-idea', '/story-elements']) ? 'active' : ''}`} onClick={toggleWriteDropdown}>
          <div className="nav-group">
                  <div className="nav-pair">
                    <div><FontAwesomeIcon icon={faPen}/></div>
                    <div>Write</div>
                  </div>
                  <div className="nav-chevron">
                    {isWriteDropdownOpen ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
                  </div>
                </div>
            {isWriteDropdownOpen && (
              <div className="write-dropdown-content">
                <NavLink to={`prompt-generators`} className='navLink' >Prompts</NavLink>
                {/* <NavLink to={`get-idea`} className='navLink' >Get Idea</NavLink> */}
                <NavLink to={`write-idea`} className='navLink'>Write Idea</NavLink>
                <NavLink to={'story-elements'} className='navLink' >Story Elements</NavLink>
              </div>
            )}
          </li>

          <li className={`navLink community-dropdown ${isActive(['/users', '/forum', '/about', '/contests']) ? 'active' : ''}`} onClick={toggleCommunityDropdown}>
          <div className="nav-group">
                  <div className="nav-pair">
                    <div><FontAwesomeIcon icon={faHouse}/></div>
                    <div>Community</div>
                  </div>
                  <div className="nav-chevron">
                    {isCommunityDropdownOpen ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
                  </div>
                </div>
            {isCommunityDropdownOpen && (
              <div className="community-dropdown-content">
                <NavLink to={`members`} className='navLink' >Members</NavLink>
                <NavLink to={`forum`} className='navLink' >Forum</NavLink>
                <NavLink to={'about'} className='navLink' >About</NavLink>
                <NavLink to={'contests'} className='navLink' >Contests</NavLink>
              </div>
            )}
          </li>

          <NavLink to={'blog'}className='navLink' >
          <div className="nav-group">
                  <div className="nav-pair">
                    <div><FontAwesomeIcon icon={faBlog}/></div>
                    <div>Blog</div>
                  </div>
                </div>
          </NavLink>
          <NavLink to={'potd'} className='navLink' >
          <div className="nav-group">
                  <div className="nav-pair">
                    <div><FontAwesomeIcon icon={faCalendar}/></div>
                    <div>POTD</div>
                  </div>
                </div>
          </NavLink>

          {!user && <NavLink to={'/login'} className='navLink'>Login</NavLink>}
          {user && (
            <li className="user-dropdown">
              <div className='navLink' onClick={toggleDropdown}>

                <div className="nav-group">
                  <div className="nav-pair">
                    <div><FontAwesomeIcon icon={faUser}/></div>
                    <div>~{user.username}</div>
                  </div>
                  <div className="nav-chevron">
                    {isDropdownOpen ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
                  </div>

                </div>
              
              </div>
              {isDropdownOpen && (
                <div className="user-dropdown-content">
                  <NavLink to={`/${user.username}`} className='navLink' >Profile</NavLink>
                  <NavLink to={`account`} className='navLink' >Account</NavLink>
                  {user.username === 'dave' && <NavLink to={`admin`} className='navLink'>Admin</NavLink>}
                  {user.username === 'dave' && <NavLink to={`blog-builder`} className='navLink'>Blog Builder</NavLink>}
                  <div onClick={handleSignOut} className='navLink'>Log Out</div>
                </div>
              )}
            </li>
          )}
        </ul>
  


        {/* Mobile Dropdown Navbar */}
        <span className="mobile-search"><SearchBar /></span>
        <div className="navbar">
          <div className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
            <button className="dropbtn" onClick={toggleDropdown}>
              <img alt="" src="/images/brand/favlogotrans.png" id='favLogo-mobile' />
            </button>
            <div className="dropdown-content" onClick={toggleDropdown}>
              <NavLink to={`/`} className='dropLink' >Home</NavLink>
              <NavLink to={`stories`} className='dropLink' >Read</NavLink>
              <NavLink to={`prompt-generators`} className='dropLink' >Prompt Generators</NavLink>
              {user && (
                <>
                  <NavLink to={`/${user.username}`} className='dropLink' >Profile</NavLink>
                  <NavLink to={`account`} className='dropLink' >Account</NavLink>
                  {user.username === 'dave' && <NavLink to={`admin`} className='dropLink'>Admin</NavLink>}
                  
                </>
              )}
              {/* <NavLink to={`get-idea`} className='dropLink' >Get an Idea</NavLink> */}
              <NavLink to={`potd`} className='dropLink' >POTD</NavLink>
              <NavLink to={`write-idea`} className='dropLink' >Write Your Idea</NavLink>
              <NavLink to={'story-elements'} className='dropLink' >Story Elements</NavLink>
              <NavLink to={`members`} className='dropLink' >Members</NavLink>
              <NavLink to={`forum`} className='dropLink' >Forum</NavLink>
              <NavLink to={'about'} className='dropLink' >About</NavLink>
              <NavLink to={'contests'} className='dropLink' >Contests</NavLink>
              <NavLink to={'blog'} className='dropLink' >Blog</NavLink>
              {user ? (
                <>
                  <div onClick={handleSignOut} className='dropLink'>Log Out</div>

                </>) : (
                  <NavLink to={'login'} className='dropLink' >Login</NavLink>
                )}
              
            </div>
          </div>
        </div>
        
      </nav>
    </div>
  );
};
