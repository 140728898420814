import React, { useState } from "react";
import './stories.css';
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeart, faStar, faEye } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';
import { DisplayGenreIcon } from "../../utilities/displayGenreIcon";
import { formatDate } from "../../utilities/formatDate";
import { useDispatch, useSelector } from "react-redux";
import JsonToReact from "../../utilities/jsonToReact";
import { firestore } from "../../firebase/firebase";
import { setActivity, setStories } from "../../app/appSlice";

export const Stories = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { username } = useParams();
    const { stories, user, activity } = useSelector(state => state.app);
    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const userStories = stories.filter(s => s.user === username);

    // State to handle the editing story and its text
    const [isEditing, setIsEditing] = useState(null);
    const [editedStoryText, setEditedStoryText] = useState("");


    const handleStoryClick = (storyId) => {
        navigate(`/${username}/${storyId}`);
    };

    // Toggle edit mode for a story
    const handleEditClick = (story) => {
        setIsEditing(story.uid);
        setEditedStoryText(story.story); // Pre-fill with existing story text
    };

    const handleDeleteStory = async (story) => {
        try {
            // Reference to the story document
            const storyRef = doc(firestore, `stories`, story.id);
    
            // Delete associated activities for the story
            const activityCollection = collection(firestore, `activity`);
            const activitiesSnap = await getDocs(activityCollection);
            
            activitiesSnap.forEach((doc) => {
                const activityData = doc.data();
                // If the activity is related to this story, delete it
                if (activityData.storyId === story.id) {
                    deleteActivity(activityData.actId); // Call deleteActivity with actId
                }
            });
    
            // Now delete the story
            await deleteDoc(storyRef);
    
            // Step 2: Update the global state by removing the deleted story
            const updatedStories = stories.filter(s => s.id !== story.id);
            dispatch(setStories(updatedStories));
    
            // Navigate back to the user’s profile or any desired route
            navigate(`/${username}`);
        } catch (error) {
            console.error("Error deleting story and activities:", error);
        }
    };

    const deleteActivity = async (id) => {
        if (!user) return;
        // Reference to the activity collection
        const activityCollection = collection(firestore, `activity`);
        // Query to find the activity document to delete
        const activityQuery = query(activityCollection, where("actId", "==", id));
        const activitySnap = await getDocs(activityQuery);
    
        if (!activitySnap.empty) {
            // Delete the first matching activity document
            const activityDoc = activitySnap.docs[0];
            await deleteDoc(activityDoc.ref);
            
            // Step 2: Update global state by removing the deleted activity
            const updatedActivities = activity.filter(activity => activity.actId !== id);
            dispatch(setActivity(updatedActivities)); // Dispatch the updated activities
        }
    };

    // Function to handle story text changes
    const handleStoryChange = (event) => {
        setEditedStoryText(event.target.value);
    };

    // Update story in Firestore and global state
    const handleStoryUpdate = async (story) => {
        const storyRef = doc(firestore, `stories`, story.uid);

        try {
            // Update Firestore
            await updateDoc(storyRef, { story: editedStoryText });

            // Update global state
            const updatedStories = stories.map((s) =>
                s.uid === story.uid ? { ...s, story: editedStoryText } : s
            );
            dispatch(setStories(updatedStories));

            // Exit edit mode
            setIsEditing(null);
            console.log("Story updated successfully");
        } catch (error) {
            console.error("Error updating story:", error);
        }
    };

    if (!userStories || userStories.length === 0) {
        return (
            <div className="stories-page">
                <p>Write a <Link to={'/prompt-generators'} className="link">story!</Link></p>
            </div>
        );
    }

    // Sort the stories by time, if needed
    const sortedStories = [...userStories].sort((a, b) => new Date(b.time) - new Date(a.time));

    return (
        <div className='stories-page'>
            <h2>{sortedStories.length} Stories</h2>
            <div className='prof-stories'>
                {sortedStories.map(story => (
                    <div key={story.uid} className='prof-story'>
                        <div className='prof-story-details' onClick={() => handleStoryClick(story.uid)}>
                            <h3 className='storyTitle'>{story.title}</h3>
                            <DisplayGenreIcon genres={story.genres} style={{ width: '50px', borderRadius: '25px', margin: '.3rem' }} />

                            <p className="prof-prompt">{JsonToReact({ jsonString: story.prompt })}</p>
                            <p className="prof-snippet"><i>{story.story.substring(0, 340)}...</i></p>
                        </div>

                        <div className='story-interaction'>
                            <div>
                                <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {story.likes ? story.likes.length : 0}</span>
                                {story.rateable && <span><FontAwesomeIcon id='star' icon={faStar} /> {story.ratings ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length).toFixed(1) : 0} ({story.ratings ? story.ratings.length : 0})</span>}
                                <span><FontAwesomeIcon id='comment' icon={faComment} /> {story.comments ? story.comments.length : 0}</span>
                                <span><FontAwesomeIcon id='heart' icon={faHeart} /> {story.favorites ? story.favorites.length : 0}</span>
                                <span><FontAwesomeIcon id='eye' icon={faEye} /> {story.views}</span>
                            </div>
                            <div>
                                <span>{formatDate(story.time)}</span>
                            </div>
                        </div>

                        {user?.username ===username && <button onClick={() => handleEditClick(story)}>Edit Story</button>}

                        {/* Dropdown editor for story text */}
                        {isEditing === story.uid &&(
                            <div className="story-editor">
                                <textarea
                                    value={editedStoryText}
                                    onChange={handleStoryChange}
                                    rows={20}
                                    className="story-edit-textarea"
                                />
                                <button onClick={() => handleStoryUpdate(story)} className="edit-story-button">Save Changes</button>
                                <button onClick={() => setIsEditing(null)} className="edit-story-button">Cancel</button>
                                {user && username === user.username && (
                                    <>
                                        <button className='delete-story-button' onClick={() => setShowDeletePopup(true)}>Delete Story</button>
                                        {showDeletePopup && (
                                            <div className='delete-popup'>
                                                <div className='delete-popup-content'>
                                                    <p>Are you sure you want to delete this story?</p>
                                                    <button className='delete-story-button' onClick={()=>handleDeleteStory(story)}>Confirm Delete</button>
                                                    <button className='delete-story-button' onClick={() => setShowDeletePopup(false)}>Cancel</button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        
                    </div>
                ))}
            </div>
        </div>
    );
};
