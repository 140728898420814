import React, { useEffect, useMemo, useRef, useState } from "react";
import { collection, addDoc, deleteDoc, doc, setDoc, getDocs, query, where, runTransaction, arrayUnion, arrayRemove } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import Filter from 'bad-words';
import './forum.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RightSide } from "../../app/rightSide/rightSide";
import { LeftSide } from "../../app/leftSide/leftSide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from "react-redux";
import { setActivity, setForumQuestions, setForumAnswers, setForumComments } from '../../app/appSlice'; // Redux actions
import { formatDate } from "../../utilities/formatDate";
import { faComments, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ForumSearch } from "./forumSearch";
import { faThumbsUp as faThumbsUpOutline } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';

const filter = new Filter();
filter.removeWords('butt', 'hell','hells','ass')


export const Forum = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    // Getting data from global state
    const questions = useSelector((state) => state.app.forumQuestions);
    const answers = useSelector((state) => state.app.forumAnswers);
    const comments = useSelector((state) => state.app.forumComments);
    const { user, activity } = useSelector((state) => state.app);
  
    const [newQuestion, setNewQuestion] = useState('');
    const [newReply, setNewReply] = useState({});
    const [newComment, setNewComment] = useState({});
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const [deleteType, setDeleteType] = useState('');
    const [replyDropdown, setReplyDropdown] = useState({});
    const [commentDropdown, setCommentDropdown] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [newQuestionCategory, setNewQuestionCategory] = useState('General Discussion');
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 12;
    const location = useLocation();

    const isFirstRender = useRef(true);

    useEffect(() => {
        // Check if it's the first render
        if (isFirstRender.current) {
            window.scrollTo({ top: 0, behavior: "smooth" });

            // Mark as no longer the first render
            isFirstRender.current = false;
        }
    }, []);

  
    const organizedQuestions = useMemo(() => {
        return questions.map((question) => {
          const questionAnswers = answers.filter((answer) => answer.questionId === question.id);
          const populatedAnswers = questionAnswers.map((answer) => ({
            ...answer,
            comments: comments.filter((comment) => comment.answerId === answer.id),
          }));
          return { ...question, answers: populatedAnswers };
        });
      }, [questions, answers, comments]);
      
    
      // Pagination and sorting logic
      const sortedQuestions = useMemo(() => (
        organizedQuestions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      ), [organizedQuestions]);
    
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const currentPosts = sortedQuestions.slice(indexOfFirstPost, indexOfLastPost);
    
    
      // Change page function
      const handleChangePage = (event, value) => {
        navigate('/forum');
        setCurrentPage(value);
      };
    
      // Handle URL hash for scrolling to a specific question
      useEffect(() => {
        if (location.hash) {
          const elementId = location.hash.replace('#', '');
          const elementIndex = sortedQuestions.findIndex((q) => q.id === elementId);
          if (elementIndex !== -1) {
            const targetPage = Math.floor(elementIndex / postsPerPage) + 1;
            if (targetPage !== currentPage) {
              setCurrentPage(targetPage);
            } else {
              setTimeout(() => {
                document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
              }, 0);
            }
          }
        }
      }, [location, sortedQuestions, currentPage]);
  
    
  
    // Get the date of the last reply
    const getLastReplyDate = (answers) => {
      if (answers.length === 0) return null;
      const lastReply = answers.reduce((latest, answer) => {
        if (answer.comments.length > 0) {
          const lastCommentDate = answer.comments.reduce(
            (latestComment, comment) => (new Date(comment.createdAt) > latestComment ? new Date(comment.createdAt) : latestComment),
            new Date(answer.createdAt)
          );
          return lastCommentDate > latest ? lastCommentDate : latest;
        }
        return new Date(answer.createdAt) > latest ? new Date(answer.createdAt) : latest;
      }, new Date(0));
      return lastReply;
    };


    const handleLike = async (answer, questionId) => {
        if (!user) return;
        
        const answerRef = doc(firestore, `forum-answers`, answer.id);
        const alreadyLiked = answer.likes?.find(like => like.username === user.username); // Check if the user has liked the story
        const likeId = new Date().toISOString(); // Unique ID for the like activity
    
        try {
            await runTransaction(firestore, async (transaction) => {
                if (alreadyLiked) {
                    // Remove the like and delete the activity
                    transaction.update(answerRef, {
                        likes: arrayRemove(alreadyLiked) // Remove the like object (which contains username and likeId)
                    });
                    await deleteLike(alreadyLiked.likeId); // Use the stored likeId to delete the activity
                } else {
                    // Add the new like and store the likeId
                    const newLike = {
                        username: user.username,
                        likeId: likeId // Store the likeId along with the username
                    };
    
                    transaction.update(answerRef, {
                        likes: arrayUnion(newLike) // Add the new like object
                    });
                    await addActivity('liked', answer.username, questionId, null, answer.id, likeId); // Track the 'liked' activity
                }
            });

    
            // Update local state after the transaction completes
            const updatedLikes = alreadyLiked
                ? answer.likes.filter(like => like.username !== user.username)
                : [...(answer.likes || []), { username: user.username, likeId }];
    
            const updatedAnswer = { ...answer, likes: updatedLikes };
            setForumAnswers(updatedAnswer);
    
            // Update global stories state
            const updatedAnswers = answers.map((s) =>
                s.id === answer.id ? updatedAnswer : s
            );
            dispatch(setForumAnswers(updatedAnswers));

    
        } catch (error) {
            console.error("Error handling like:", error);
        }
    };

    const deleteLike = async (id) => {
        if (!user) return;
        // Reference to the activity collection
        const activityCollection = collection(firestore, `activity`);
        // Query to find the activity document to delete
        const activityQuery = query(activityCollection, where("forumId", "==", id));
        const activitySnap = await getDocs(activityQuery);
    
        if (!activitySnap.empty) {
            // Delete the first matching activity document
            const activityDoc = activitySnap.docs[0];
            await deleteDoc(activityDoc.ref);
            
            // Step 2: Update global state by removing the deleted activity
            const updatedActivities = activity.filter(activity => activity.forumId !== id);
            dispatch(setActivity(updatedActivities)); // Dispatch the updated activities
        }
    };
  
    const addActivity = async (action, username, id, text, answerId, forumId) => {
        if (!user) return;

        const newDocRef = doc(collection(firestore, `activity`));

        const docData = {
            user: user.username,
            action: action,
            time: new Date().toISOString(),
            questionId: id,
            username: username,
            type: 'forum',
            text: text,
            forumId: forumId,
            answerId: answerId
        };


        try {
            await setDoc(newDocRef, docData);
            // Update global activity stat
            dispatch(setActivity([...activity, docData]));
        } catch (error) {
            console.log('Error adding document:', error);
        }
    };
    
    // Handle question submission
    const handleQuestionSubmit = async () => {
        // Check if the user is logged in
        if (!user) {
            navigate('/login');
            return;
        }

        if (filter.isProfane(newQuestion)) {
            alert("Your question contains inappropriate language. Please revise it.");
            return; // Stop the submission
        }
    
        // Validate the length of the question
        if (newQuestion.length > 2500) {
            alert('Too long of a post');
            return;
        }
    
        // Check if the question text is not empty
        if (newQuestion.trim() !== '') {
            const newQuestionData = {
                // Do not assign a temporary ID; Firestore will generate one
                user: user.username,
                text: newQuestion,
                createdAt: new Date().toISOString(),
                category: newQuestionCategory,
            };
    
            // Optimistically update the UI by adding the new question to the state
            const updatedQuestions = [...questions, newQuestionData];
            dispatch(setForumQuestions(updatedQuestions));
            setNewQuestion('');
    
            try {
                // Add the new question to Firestore
                const docRef = await addDoc(collection(firestore, 'forum-questions'), newQuestionData);
    
                // Update the new question data with the Firestore document ID
                const updatedQuestionsWithId = updatedQuestions.map((q) => 
                    q.text === newQuestionData.text ? { ...q, id: docRef.id } : q
                );
    
                // Set the updated questions in the state with the Firestore ID
                dispatch(setForumQuestions(updatedQuestionsWithId));
    
                // Log the activity with the Firestore document ID
                addActivity('posted', user.username, docRef.id, newQuestionData.text, null, null);
            } catch (err) {
                console.error('Failed to submit question:', err);
                alert('Error submitting question. Please try again later.');
            }
        } else {
            alert('Question cannot be empty.');
        }
    };
    
    
    
  
    // Handle reply submission
    const handleReplySubmit = async (questionId, username) => {
      if (!user) {
        navigate('/login');
        return;
      }
  
      const replyText = newReply[questionId]?.trim();
      if (replyText.length > 2500) {
        alert('Too long of a post');
        return;
      }

      if (filter.isProfane(replyText)) {
        alert("Your reply contains inappropriate language. Please revise it.");
        return; // Stop the submission
    }
      if (replyText !== '') {
        const replyData = {
          user: user.username,
          text: replyText,
          createdAt: new Date().toISOString(),
          questionId,
          comments: [], // Initialize with no comments
        };
  
        // Optimistically update the UI by adding the new reply to the state
        const updatedAnswers = [...answers, replyData];
        dispatch(setForumAnswers(updatedAnswers));
        setNewReply((prevReplies) => ({ ...prevReplies, [questionId]: '' }));
  
        try {
          const replyRef = await addDoc(collection(firestore, 'forum-answers'), replyData);
          // Update with the actual ID from Firestore
          const updatedAnswersWithId = updatedAnswers.map((r) =>
            r.id === replyData.id ? { ...r, id: replyRef.id } : r
          );
          dispatch(setForumAnswers(updatedAnswersWithId));
          addActivity('answered', username, questionId, replyData.text, null, replyRef.id);
        } catch (err) {
          console.error('Failed to submit reply', err);
        }
      }
    };
  
    // Handle comment submission
    const handleCommentSubmit = async (questionId, answerId, username) => {
      if (!user) {
        navigate('/login');
        return;
      }
  
      const commentText = newComment[`${questionId}_${answerId}`]?.trim();
      if (commentText.length > 2500) {
        alert('Too long of a post');
        return;
      }

      if (filter.isProfane(commentText)) {
        alert("Your comment contains inappropriate language. Please revise it.");
        return; // Stop the submission
    }
      if (commentText !== '') {
        const commentData = {
          user: user.username,
          text: commentText,
          createdAt: new Date().toISOString(),
          answerId,
          questionId,
        };
  
        // Optimistically update the UI by adding the new comment to the state
        const updatedComments = [...comments, commentData];
        dispatch(setForumComments(updatedComments));
        setNewComment((prevComments) => ({
          ...prevComments,
          [`${questionId}_${answerId}`]: '',
        }));
  
        try {
          const commentRef = await addDoc(collection(firestore, 'forum-comments'), commentData);
          // Update with the actual ID from Firestore
          const updatedCommentsWithId = updatedComments.map((c) =>
            c.id === commentData.id ? { ...c, id: commentRef.id } : c
          );
          dispatch(setForumComments(updatedCommentsWithId));
          addActivity('commented on', username, questionId, commentData.text, answerId, commentRef.id);
        } catch (err) {
          console.error('Failed to submit comment', err);
        }
      }
    };
  
const handleDeletePost = async () => {
    if (!user) {
        navigate('/login');
        return;
    }

    let updatedQuestions;
    let updatedActivities;

    try {
        // Optimistically update the UI first
        if (deleteType === "question") {
            // Optimistically remove the question from state
            updatedQuestions = questions.filter(question => question.id !== postToDelete);
            dispatch(setForumQuestions(updatedQuestions));

            updatedActivities = (activity || []).filter(a => a.questionId !== postToDelete);
            dispatch(setActivity(updatedActivities));

            // Proceed with Firestore deletion
            await deleteDoc(doc(firestore, 'forum-questions', postToDelete));

            // Fetch and delete all answers and comments related to the deleted question
            const answersSnapshot = await getDocs(query(collection(firestore, 'forum-answers'), where('questionId', '==', postToDelete)));
            const deleteAnswerPromises = answersSnapshot.docs.map(async (doc) => {
                const answerId = doc.id;
                await deleteDoc(doc.ref); // Delete answer

                const commentsSnapshot = await getDocs(query(collection(firestore, 'forum-comments'), where('answerId', '==', answerId)));
                const deleteCommentPromises = commentsSnapshot.docs.map(commentDoc => deleteDoc(commentDoc.ref));
                await Promise.all(deleteCommentPromises); // Delete all comments

                const activitySnapshot = await getDocs(query(collection(firestore, 'activity'), where('questionId', '==', postToDelete), where('forumId', '==', answerId)));
                const deleteActivityPromises = activitySnapshot.docs.map(activityDoc => deleteDoc(activityDoc.ref));
                await Promise.all(deleteActivityPromises); // Delete related activities
            });
            await Promise.all(deleteAnswerPromises);

            // Also delete all activities related to the question
            const activitySnapshot = await getDocs(query(collection(firestore, 'activity'), where('questionId', '==', postToDelete)));
            const deleteActivityPromises = activitySnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deleteActivityPromises);

        } else if (deleteType === "reply") {
            const [, answerId] = postToDelete.split('_');
            // Optimistically update state
            const updatedAnswers = answers.filter(answer => answer.id !== answerId);
            dispatch(setForumAnswers(updatedAnswers));

            updatedActivities = (activity || []).filter(a => a.forumId !== answerId);
            dispatch(setActivity(updatedActivities));

            // Proceed with Firestore deletion
            await deleteDoc(doc(firestore, 'forum-answers', answerId));

            // Fetch and delete all comments related to this answer
            const commentsSnapshot = await getDocs(query(collection(firestore, 'forum-comments'), where('answerId', '==', answerId)));
            const deleteCommentPromises = commentsSnapshot.docs.map(commentDoc => deleteDoc(commentDoc.ref));
            await Promise.all(deleteCommentPromises);

            // Delete activities related to this reply
            // Query Firestore for activity records where either forumId or answerId matches the answerId
            const forumIdSnapshot = await getDocs(query(collection(firestore, 'activity'), where('forumId', '==', answerId)));
            const answerIdSnapshot = await getDocs(query(collection(firestore, 'activity'), where('answerId', '==', answerId)));

            // Combine the two query snapshots
            const allActivityDocs = [...forumIdSnapshot.docs, ...answerIdSnapshot.docs];

            // Remove duplicates if necessary
            const uniqueActivityDocs = allActivityDocs.reduce((acc, doc) => {
                if (!acc.some(existingDoc => existingDoc.id === doc.id)) {
                    acc.push(doc);
                }
                return acc;
            }, []);

            // Delete all related activity documents
            const deleteActivityPromises = uniqueActivityDocs.map(activityDoc => deleteDoc(activityDoc.ref));
            await Promise.all(deleteActivityPromises);
            
        } else if (deleteType === "comment") {
            const [, , commentId] = postToDelete.split('_');
            // Optimistically update state
            const updatedComments = comments.filter(comment => comment.id !== commentId);
            dispatch(setForumComments(updatedComments));
        

            updatedActivities = (activity || []).filter(a => a.forumId !== commentId);
            dispatch(setActivity(updatedActivities));

            try {
                // Proceed with Firestore deletion of the comment
                await deleteDoc(doc(firestore, 'forum-comments', commentId));
        
                // Delete related activities
                const activitySnapshot = await getDocs(query(collection(firestore, 'activity'), where('forumId', '==', commentId)));
                const deleteActivityPromises = activitySnapshot.docs.map(activityDoc => deleteDoc(activityDoc.ref));
                await Promise.all(deleteActivityPromises);
        
            } catch (err) {
                console.error("Failed to delete comment:", err);
        
                // Revert the state to the original questions state in case of failure
                dispatch(setForumQuestions(questions));
            } finally {
                setPostToDelete(null);
                setShowDeletePopup(false);
            }
        }

    } catch (err) {
        console.error("Failed to delete post", err);

        // If an error occurs, we may want to revert the optimistic update
        if (deleteType === "question") {
            dispatch(setForumQuestions(questions)); // Revert to original questions
        } else if (deleteType === "reply" || deleteType === "comment") {
            dispatch(setForumQuestions(questions)); // Revert to original state
        }
    } finally {
        setPostToDelete(null);
        setShowDeletePopup(false);
    }
};

  
    const toggleReplies = (questionId) => {
      setReplyDropdown((prevState) => ({
        ...prevState,
        [questionId]: !prevState[questionId],
      }));
    };
  
    const toggleComments = (answerId) => {
      setCommentDropdown((prevState) => ({
        ...prevState,
        [answerId]: !prevState[answerId],
      }));
    };
  
    

    const countTotalComments = (answers) => {
        return answers.reduce((total, answer) => total + answer.comments.length, 0);
    };

    const totalPages = Math.ceil(sortedQuestions.length / postsPerPage);


    return (
        
        <div className="page">
            <LeftSide />
            <div className='content' id='forum-container'>
                <header><Link to= '/forum' className="link"><h1>Writician Community Forum</h1></Link></header>
                
                    <div className='forum-content'>
                        <div className="forum-top">
                            <div className='new-question-section'>
                                <textarea
                                    value={newQuestion}
                                    onChange={(e) => setNewQuestion(e.target.value)}
                                    placeholder='Ask a question...'
                                    className='forum-input'
                                    maxLength={3000}
                                />
                                <select
                                    value={newQuestionCategory}
                                    onChange={(e) => setNewQuestionCategory(e.target.value)}
                                    className='category-select'
                                >
                                    <option value="General Discussion">General Discussion</option>
                                    <option value="Community Guidelines">Community Guidelines</option>
                                    <option value="Announcements">Announcements</option>
                                    <option value="Writing Contests">Writing Contests</option>
                                    <option value="Plot Development">Plot Development</option>
                                    <option value="Character Development">Character Development</option>
                                    <option value="World-Building">World-Building</option>
                                    <option value="Writing Craft">Writing Craft</option>
                                    <option value="Editing and Revision">Editing and Revision</option>
                                    <option value="Introductions">Introductions</option>
                                    <option value="Off-Topic Chat">Off-Topic Chat</option>
                                    <option value="Writing Life">Writing Life</option>
                                    <option value="Website Issues">Admin/Bugs/Website Issues</option>
                                </select>
                                <button className='question-button' onClick={handleQuestionSubmit}>Submit Question</button>
                            </div>
                            <div className='category-filter'>
                                <label>Filter by category:</label>
                                <select
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    className='category-select'
                                >
                                    <option value="All">All</option>
                                    <option value="General Discussion">General Discussion</option>
                                    <option value="Community Guidelines">Community Guidelines</option>
                                    <option value="Announcements">Announcements</option>
                                    <option value="Writing Contests">Writing Contests</option>
                                    <option value="Plot Development">Plot Development</option>
                                    <option value="Character Development">Character Development</option>
                                    <option value="World-Building">World-Building</option>
                                    <option value="Writing Craft">Writing Craft</option>
                                    <option value="Editing and Revision">Editing and Revision</option>
                                    <option value="Introductions">Introductions</option>
                                    <option value="Off-Topic Chat">Off-Topic Chat</option>
                                    <option value="Writing Life">Writing Life</option>
                                    <option value="Website Issues">Admin/Bugs/Website Issues</option>
                                </select>
                            </div>
                            <ForumSearch category= {selectedCategory} />
                        </div>
                        <div className='questions-list'>
                            {currentPosts.map((question, idx) => (
                                <div key={idx} className='forum-question' id={question.id}>
                                    <div className="forum-top">
                                        <p>Category: <strong>{question.category}</strong></p>
                                        <p><Link className='username' to={`/${question.user}`}><strong>~{question.user}</strong></Link> asked:</p>
                                        <p className="forum-text"><span dangerouslySetInnerHTML={{ __html: question.text }}></span></p>
                                        <div className="forum-stats">
                                            <p>Posted on: <strong>{formatDate(question.createdAt)}</strong></p>
                                            <p>Last Reply: <strong>{question.answers.length > 0 ? formatDate(getLastReplyDate(question.answers)): 'No replies yet'}</strong></p>
                                            
                                        </div>
                                        <div className="forum-buttons">
                                            <button className={`toggle-replies-button ${replyDropdown[question.id] ? 'active' : ''}`} onClick={() => toggleReplies(question.id)}>
                                                {question.answers.length} <FontAwesomeIcon icon={faComment} /> {`${countTotalComments(question.answers)}`} <FontAwesomeIcon icon={faComments} />
                                            </button>
                                            {user && question.user === user.username && (
                                                    <button className='delete-button' onClick={() => { setPostToDelete(question.id); setDeleteType("question"); setShowDeletePopup(true); }}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                                )}
                                        </div>
                                    </div>
                                    {replyDropdown[question.id] && (
                                        <div className='replies-section'>
                                                <textarea
                                                    value={newReply[question.id] || ""}
                                                    onChange={(e) => setNewReply({ ...newReply, [question.id]: e.target.value })}
                                                    placeholder='Reply to this question...'
                                                    className='forum-input'
                                                />
                                                <button className='submit-button' onClick={() => handleReplySubmit(question.id, question.user)}>Submit Reply</button>
                                            <div className='replies-list'>
                                                {question.answers.map((answer, replyIdx) => (
                                                    <div key={replyIdx}>
                                                            <div className="reply-top">
                                                                <p><Link className='username' to={`/${answer.user}`}><strong>~{answer.user}</strong></Link> replied:</p>
                                                                <p className="forum-text"><span dangerouslySetInnerHTML={{ __html: answer.text }}></span></p>
                                                                <span className="reply-date">{formatDate(answer.createdAt)}</span>
                                                                <div className="forum-buttons">
                                                                    <div className="forum-like-comment">
                                                                        <button className='forum-like-button' onClick={()=>handleLike(answer, question.id)} disabled ={!user || (user && user.username ===answer.user) ? true : false}>
                                                                            <FontAwesomeIcon
                                                                                icon={answer.likes && answer.likes.some(like => like.username === user?.username) ? faThumbsUpFilled : faThumbsUpOutline

                                                                                }
                                                                                className='like-icon'
                                                                                
                                                                            />
                                                                            {answer.likes ? answer.likes.length : 0}
                                                                        </button>
                                                                        <div className='forum-comment-button'>
                                                                            <button
                                                                                className={`toggle-comments-button ${commentDropdown[answer.id] ? 'active' : ''}`}  
                                                                                onClick={() => toggleComments(answer.id)}
                                                                            >
                                                                                {answer.comments.length} <FontAwesomeIcon icon={faComment} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {user && answer.user === user.username && (
                                                                        <button className='delete-button' onClick={() => {setPostToDelete(`${question.id}_${answer.id}`); setDeleteType("reply"); setShowDeletePopup(true); }}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                                                    )}

                                                                </div>
                                                                    {commentDropdown[answer.id] && (
                                                                <div className='comment-section'>
                                                                    <textarea
                                                                        value={newComment[`${question.id}_${answer.id}`] || ""}
                                                                        onChange={(e) => setNewComment({ ...newComment, [`${question.id}_${answer.id}`]: e.target.value })}
                                                                        placeholder='Comment on this reply...'
                                                                        className='forum-input'
                                                                    />
                                                                    <br/>
                                                                    <button className='submit-button' onClick={() => handleCommentSubmit(question.id, answer.id, answer.user)}>Submit Comment</button>
                                                                    <div className='comments-list'>
                                                                        {answer.comments.map((comment, commentIdx) => (
                                                                            <div key={commentIdx} className='forum-comment comment-top'>
                                                                                <p><Link className='username' to={`/${comment.user}`}><strong>~{comment.user}</strong></Link> commented:</p>
                                                                                <p className="forum-text"><span dangerouslySetInnerHTML={{ __html: comment.text }}></span></p>
                                                                                <div className="forum-buttons">
                                                                                    <span className="reply-date">{formatDate(comment.createdAt)}</span>
                                                                                    {user && comment.user === user.username && (
                                                                                        <button className='delete-button' onClick={() => { setPostToDelete(`${question.id}_${answer.id}_${comment.id}`); setDeleteType("comment"); setShowDeletePopup(true); }}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                                                                    )}
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            </div>
                                                        
                                                        
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {totalPages>1 &&<Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                    style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                    />}
            </div>
            <RightSide />
            {showDeletePopup && (
                <div className='delete-popup'>
                    <div className ='delete-popup-content'>
                    <p>Are you sure you want to delete this {deleteType}?</p>
                    <button onClick={handleDeletePost}>Yes</button>
                    <button onClick={() => setShowDeletePopup(false)}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

