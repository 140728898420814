import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEye, faHeart, faStar, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from "../../../utilities/formatDate";
import './discoverStories.css';
import { DisplayGenreIcon } from "../../../utilities/displayGenreIcon";
import JsonToReact from "../../../utilities/jsonToReact";
import { GenreSelector } from "../../../utilities/genreSelector/genreSelector";

export const DiscoverStories = () => {
    const { stories } = useOutletContext();
    const [filteredStories, setFilteredStories] = useState([]);
    const [activeView, setActiveView] = useState('random');
    const [expandedStories, setExpandedStories] = useState({ 0: true }); // Start with first story expanded
    const navigate = useNavigate();

    useEffect(() => {
        if (stories && stories.length > 0) {
            filterStories('random', stories);
        }
    }, [stories]);

    const filterStories = (view, stories) => {
        let filtered = [];
        const storiesCopy = [...stories];

        if (view === 'random') {
            const shuffledStories = storiesCopy.sort(() => Math.random() - 0.5);
            filtered = shuffledStories.slice(0, 10);
        } else if (view === 'recent') {
            const sortedStories = storiesCopy.sort((a, b) => b.time.seconds - a.time.seconds);
            const recentStories = sortedStories.slice(0, 50).sort(() => Math.random() - 0.5).slice(0, 10);
            filtered = recentStories;
        } else if (view === 'popular') {
            const popularStories = storiesCopy.map(story => {
                const views = story.views || 0;
                const likes = (story.likes ? story.likes.length : 0) * 10;
                const comments = (story.comments ? story.comments.length : 0) * 50;
                const replies = (story.comments ? story.comments.reduce((total, comment) => total + (comment.replies ? comment.replies.length : 0), 0) : 0) * 50;
                const ratings = (story.rateable && story.ratings ? story.ratings.length * (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length) : 0) * 20;

                return {
                    ...story,
                    influence: views + likes + comments + replies + ratings
                };
            });

            popularStories.sort((a, b) => b.influence - a.influence);
            filtered = popularStories.slice(0, 10);
        } else if (view === 'winners') {
            filtered = []; // Placeholder for contest winners logic
        }

        setFilteredStories(filtered);
    };

    const handleNav = (view) => {
        setActiveView(view);
        filterStories(view, stories);
    };

    const handleStoryClick = (story) => {
        navigate(`/${story.user}/${story.uid}`);
    };

    const toggleExpand = (index) => {
        setExpandedStories(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    if (!stories || stories.length === 0) {
        return <div>No stories available</div>;
    }
    const genres =["science fiction", "fantasy", "mystery", "romance", "horror","literary",
        "historical fiction",]

    return (
        <div className='discover-stories-page'>
            
            <nav className="discover-nav">
                <div className={activeView === 'random' ? "active" : ""} onClick={() => handleNav('random')}>Random</div>
                <div className={activeView === 'popular' ? "active" : ""} onClick={() => handleNav('popular')}>Popular</div>
                <div className={activeView === 'recent' ? "active" : ""} onClick={() => handleNav('recent')}>Recent</div>
            </nav>
            <div className='discover-genre-selector'>
                <GenreSelector genres={genres} iconStyle={{width: '53px', marginRight: '3px'}}/>

            </div>

            <div className='discover-list'>
                {filteredStories.map((story, index) => (
                    <div key={index} className='discover-story'>
                        <div className='discover-story-details' onClick={() => handleStoryClick(story)}>
                            <div className="title-author-genres">
                                <span className="title-author">
                                    <strong className='storyTitle'>{story.title}</strong><br/>
                                    <strong className="story-author">{story.author}</strong>
                                </span>
                                <div className="icons-chevron">
                                    <DisplayGenreIcon genres={story.genres} style ={{width: '2rem', borderRadius: '2rem', marginLeft: '.5rem'}}/>
                                    <span onClick={(e) => {e.stopPropagation(); toggleExpand(index);}} className="toggle-btn">
                                        <FontAwesomeIcon icon={expandedStories[index] ? faChevronUp : faChevronDown} />
                                    </span>
                                </div>
                            </div>

                            {expandedStories[index] && (
                                <div className="story-details-prompt">
                                    {JsonToReact({ jsonString: story.prompt, omitSpan: true })}
                                </div>
                            )}

                            {expandedStories[index] && (
                                <p className="discover-snippet">
                                    <i>{story.story.substring(0, 240)}...</i>
                                </p>
                            )}
                        </div>

                        <div className='story-interaction'>
                            <div>
                                <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {story.likes ? story.likes.length : 0}</span>
                                {story.rateable && <span><FontAwesomeIcon id='star' icon={faStar} /> {story.ratings ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length).toFixed(1) : 0} ({story.ratings ? story.ratings.length : 0})</span>}
                                <span><FontAwesomeIcon id='comment' icon={faComment} /> {story.comments ? story.comments.length : 0}</span>
                                <span><FontAwesomeIcon id='heart' icon={faHeart} /> {story.favorites ? story.favorites.length : 0}</span>
                                <span><FontAwesomeIcon id='eye' icon={faEye} /> {story.views}</span>
                            </div>
                            <div>
                                <span>{formatDate(story.time)}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
